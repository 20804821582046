/*#

Give a short description of the component's use case and its options. 
Markdown is allowed for rich editing.

Examples:

<div>
    <!-- replace this comment with example HTML -->    
</div>

✔

*/

@import "../../common/variables.less";

.Checkbox {
    position: relative;
    display: inline-block;
    
    .Checkbox-input:disabled + .Checkbox-label,
    &.is-disabled .Checkbox-label {
        opacity: 0.5;
        cursor: default;
    }
}

.Checkbox-input {
    opacity: 0;
    position: absolute;
    
    // checked styling for box
    &:checked + .Checkbox-label:before {
        
    }
    
    // checked styling for checkmark
    &:checked + .Checkbox-label:after {
        -ms-transform: scale(.7);
        -webkit-transform: scale(.7);
        transform: scale(.7);
        opacity: 1;
    }
    
    // focus styling for box
    &:focus + .Checkbox-label:before {
        box-shadow: 0 0 1px 1px @colorFocus;
    }
}

.Checkbox-label {
    padding-left: 1.5em;
    position: relative;
    cursor: pointer;
    
    &:before {
        content: ' ';
        position: absolute;
        left: 0;
        top: .2em;
        width: 1em;
        height: 1em;
        display: block;
        background: white;
        border: 2px solid @colorGrey3;
        border-radius: 3px;
    }
    
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: .2em;
        width: 1em;
        height: 1em;
        display: inline-block;
        z-index: 1;
        
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjE4MS4yIDI3MyAxNyAxNiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAxODEuMiAyNzMgMTcgMTYiPjxwYXRoIGQ9Ik0tMzA2LjMgNTEuMmwtMTEzLTExM2MtOC42LTguNi0yNC04LjYtMzQuMyAwbC01MDYuOSA1MDYuOS0yMTIuNC0yMTIuNGMtOC42LTguNi0yNC04LjYtMzQuMyAwbC0xMTMgMTEzYy04LjYgOC42LTguNiAyNCAwIDM0LjNsMjMxLjIgMjMxLjIgMTEzIDExM2M4LjYgOC42IDI0IDguNiAzNC4zIDBsMTEzLTExMyA1MjQtNTI0YzctMTAuMyA3LTI1LjctMS42LTM2eiIvPjxwYXRoIGZpbGw9IiMzNzM3MzciIGQ9Ik0xOTcuNiAyNzcuMmwtMS42LTEuNmMtLjEtLjEtLjMtLjEtLjUgMGwtNy40IDcuNC0zLjEtMy4xYy0uMS0uMS0uMy0uMS0uNSAwbC0xLjYgMS42Yy0uMS4xLS4xLjMgMCAuNWwzLjMgMy4zIDEuNiAxLjZjLjEuMS4zLjEuNSAwbDEuNi0xLjYgNy42LTcuNmMuMy0uMS4zLS4zLjEtLjV6Ii8+PHBhdGggZD0iTTExODcuMSAxNDMuN2wtNTYuNS01Ni41Yy01LjEtNS4xLTEyLTUuMS0xNy4xIDBsLTI1My41IDI1My41LTEwNi4yLTEwNi4yYy01LjEtNS4xLTEyLTUuMS0xNy4xIDBsLTU2LjUgNTYuNWMtNS4xIDUuMS01LjEgMTIgMCAxNy4xbDExNC43IDExNC43IDU2LjUgNTYuNWM1LjEgNS4xIDEyIDUuMSAxNy4xIDBsNTYuNS01Ni41IDI2Mi0yNjJjNS4yLTMuNCA1LjItMTIgLjEtMTcuMXpNMTYzNC4xIDE2OS40bC0zNy43LTM3LjdjLTMuNC0zLjQtOC42LTMuNC0xMiAwbC0xNjkuNSAxNjkuNS03MC4yLTcxLjljLTMuNC0zLjQtOC42LTMuNC0xMiAwbC0zNy43IDM3LjdjLTMuNCAzLjQtMy40IDguNiAwIDEybDc3LjEgNzcuMSAzNy43IDM3LjdjMy40IDMuNCA4LjYgMy40IDEyIDBsMzcuNy0zNy43IDE3NC43LTE3Ni40YzEuNi0xLjcgMS42LTYuOS0uMS0xMC4zeiIvPjwvc3ZnPg==') no-repeat center center;
        
        -ms-transition: all .15s ease;
        -webkit-transition: all .15s ease;
        transition: all .15s ease;
        -ms-transform: scale(0);
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
}

.CheckboxOption-indicator {
    &:after {
        display: inline-block;
        width: 22px;
        height: 22px;
        border: 1px solid @colorTextGrey;
        content: "\2611";
        color: #fff;
        padding: 5px 4px;
        font-size: 11px;
        font-weight: 700;
    }

    &.is-active {
        &:after {
            background: @colorLightGreen;
            border: 1px solid @colorLightGreen;
        }
    }
}

.round.CheckboxOption-indicator {
    &.is-active {
        &:after {
                border-radius: 50%;
        }
    }
}