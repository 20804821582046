@import "../../common/variables.less";

/*#
  **Core modal component**
 
<!-- leads-modal -->
<div id="leads-modal" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Modal Header</h4>
      </div>
      <div class="modal-body">
        <p>Some text in the modal.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>

  </div>
</div>
 */

.Modal {
          background: rgba(0, 0, 0, 0.6);
          position: fixed;
          backface-visibility: hidden;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 100;
          display: none;
          padding: 1em;
        }
        .Modal.Modal--active {
          display: block;
        }
        .Modal-dialog {
          box-sizing: border-box;
          position: relative;
          background: #fbfaf9;
          margin: 15% auto 0;
          margin: 1vh auto 0;
          border-radius: 0.5em;
          z-index: 11;
          width: 100%;
          max-width: 550px;
          padding: 1em;

          @media @bpMedium {
            margin: 2vh auto 0;
          }

          @media @bpLarge {
            margin: 3vh auto 0;
          }

          @media @bpXLarge {
            margin: 7vh auto 0;
          }

          @media @bpContainer {
            margin: 15vh auto 0;
          }

        }
        .Modal-heading {
            margin-top: 0;
            margin-bottom: 0.2em;
        }
        .Modal-content {
          max-height: 50vh;
          overflow: auto;
          overflow-x: hidden;
        }
        .Modal-footer {
          background: #ebe8e2;
          margin-top: 1em;
          padding: .8em;
          border-bottom-left-radius: .5em;
          border-bottom-right-radius: .5em;
          text-align: right;
        }
        .Modal-close {
          position: absolute;
          top: .5em;
          right: .6em;
          font-size: 1.6em;
          text-decoration: none;
          padding: 0.1em 0.3em 0.1em;
          background: #ebe8e2;
          line-height: 1em;
          border-radius: 50%;
          color: #333;
        }
        .Modal-close:hover {
          background: #ebe8e2;
        }
