/*#

Styling for Sortlist.

Examples:
<div style="width:1024px;">

    <div class="u-md-hidden SortList-mobile">
     <div class="SortlistHeadingWrapper-mobile Icon">
        <h5 class="SortlistHeading-mobile Icon" data-module="toggler" data-toggler="contentElement: '.js-Sort', cssClass: 'sort-show'">Sortera</h5><a class="SortListSaveTrigger-mobile" href="#">Spara</a>
    </div>
     <div class="SortlistOptions-mobile js-Sort">
        <ul class="List List--vertical List--bordered ">
            <li class="List-item"><a class="" href="#">Alla <span class="radioOption-indicator u-pullRight Icon"></span></a></li>
            <li class="List-item"><a class="" href="#">Senaste <span class="radioOption-indicator u-pullRight Icon"></span></a></li>
            <li class="List-item"><a class="" href="#">Bilmärke (A-Ö) <span class="is-active radioOption-indicator u-pullRight Icon"></span></a></li>
            <li class="List-item"><a class="" href="#">Bilmärke (Ö-A) <span class="radioOption-indicator u-pullRight Icon"></span></a></li>
            <li class="List-item"><a class="" href="#">Pris - billigast <span class="radioOption-indicator u-pullRight Icon"></span></a></li>
            <li class="List-item"><a class="" href="#">Pris - dyrast <span class="radioOption-indicator u-pullRight Icon"></span></a></li>
            <li class="List-item"><a class="" href="#">Mil - lägst <span class="radioOption-indicator u-pullRight Icon"></span></a></li>
            <li class="List-item"><a class="" href="#">Mil - flest <span class="radioOption-indicator u-pullRight Icon"></span></a></li>
            <li class="List-item"><a class="" href="#">Årsmodell - nyast <span class="radioOption-indicator u-pullRight Icon"></span></a></li>
            <li class="List-item"><a class="" href="#">År - äldst <span class="radioOption-indicator u-pullRight Icon"></span></a></li>
        </ul>
    </div>
</div>

    <div class="Sortlist Sortlist--setPositionBottom ">
    <div class="Sortlist-headingWrapper">
        <h5 class="Sortlist-heading">Sortera</h5>
    </div>
    <div class="Sortlist-options">
        <ul class="List List--horizontal u-marginVz">
            <li class="List-item"><a class="Sortlist-arrow Icon" href="#">Senaste</a></li>
            <li class="List-item"><a class="Sortlist-arrow Icon is-active" href="#">Bilmärke (A-Ö)</a></li>
            <li class="List-item"><a class="Sortlist-arrow Icon" href="#">Pris</a></li>
            <li class="List-item"><a class="Sortlist-arrow Icon" href="#">Mil</a></li>
            <li class="List-item"><a class="Sortlist-arrow Icon" href="#">År</a></li>
            <li class="List-item u-text u-pullRight u-textUpper "><a class="u-marginHz u-textWeightNormal" href="#">+ Spara denna sökning</a></li>
        </ul>
    </div>
</div>
</div>
*/
@import "../../common/variables.less";

.Sortlist{
    position:relative;
    width:100%;
}

.SortList-mobile {
    margin-top:-6px;
    background: @colorGrey1;
    margin: 0 2.5em;
}

.Sortlist--setPositionBottom {
    padding-bottom: 15px;
}

.Sortlist-headingWrapper {
     background: @colorGrey1;
     display:inline-block;
 }

.Sortlist-heading {
    color: @colorLink;
    font-weight:300;
    font-size:17/16em;
    text-transform:uppercase;
    margin-bottom:0;
    margin-right: 25px;
    max-height:170px;
}

.object_sort .Sortlist-heading {
    @media @bpMedium {
        margin-right: 0px;
    }

    @media @bpLarge {
        margin-right: 25px;
    }
}

.Sortlist-options {
    border-bottom:3px solid @colorLightGreen;
    padding: @spaceXSmall 0;
    margin-left: -18px;
    margin-right: -17px;

    @media @bpSmall {
        margin: 0 2.5em;
        padding: @spaceXSmall @spaceSmall;
    }

    @media @bpMedium {
        margin: 0 2.5em;
        padding: @spaceXSmall @spaceSmall;
    }
    @media only screen and (max-width: 375px) {
        margin-right: -9px;
    }

    a {
        font-weight: 100;
        font-size: @textSizeMedium;
        line-height: 22px;


        @media @bpXSmall {
            margin-right: 10px;
        }

         @media @bpXLarge {
            margin-right: 15px;

        }

        &:hover {
            text-decoration: none;
        }
    }
}


.sortList-save {
    text-transform: uppercase;
    float: right;
}

a.sortListSave-trigger {
    margin: 0;
    font-weight: 500;

    span {
        display: none;

        @media @bpLarge {
            display:inline;
        }

        }

}

.Sortlist-arrow {
    &:after {
        content: "";
        padding-left: @spaceXSmall;
        position: relative;
        top: .1em;
        font-size:0.7em;
        font-family: 'BilwebIcons' !important;
    }

    &.asc {
        &:after {
            content: "\25bc";
        }
    }

    &.desc {
        &:after {
            content: "\25b2";
        }
    }
}

.Sortarrow-fix .Sortlist-arrow {
     &:after {
        padding-left: 0px;
     }
}
.dashboard .Sortarrow-fix .Sortlist-arrow {
     &:after {
        top: 0;
     }
}
.SortlistHeadingWrapper-mobile {
    padding: @spaceSmall @spaceMedium;
    margin-bottom:12px;
}
.SortlistOptions-mobile {
    display: none;
    border: 1px solid @colorGrey2;
    padding: @spaceSmall;
    border-radius: 4px;
    background: #fff;
    z-index: 10;
    width: 95.5%;
    position: absolute;

    &.sort-show {
        display:block;
    }

    &:before {
        background: #fff;
        width: 25px;
        height: 25px;
        border-right: 1px solid @colorGrey2;
        border-bottom: 1px solid @colorGrey2;
        transform: rotate(225deg);
        margin: 0 auto;
        top: -12px;
        left: 30px;
        position: absolute;
        display: block;
        content: "";
    }

    li {
        padding: @spaceSmall;

        a {
            color: @colorGrey5;
            display:block;
        }
    }
}

.SortlistHeading-mobile {
    display: inline;
    font-weight: 500;
    color: @colorLink;
    font-size:1.2em;
    margin-left: 20px;

    @media @bpSmall{
        margin-left: 0;
    }

    &:before {
        content:"\1f53d";
        color:@colorTextGrey;
        font-size:@textSizeSmall;
        padding-right:@spaceXSmall;
        font-family: 'BilwebIcons' !important;
    }
}

.SortListSaveTrigger-mobile {
    float:right;
      &:after {
        content:"\2606";
        color:@colorTextGrey;
        font-size:@textSizeMedium;
        padding-left:@spaceXSmall;
        font-weight:600;
    }
}