@import "../../common/variables.less";


.similar-objects{
    .ImageInfoBox-moreImages{
        .u-hidden;
    }
    .ImageInfoBox-date{
        @media (max-width: 767px) {
            top: 0px !important;
       }
    }
    .Slider-item{
        cursor: pointer;
    }
}