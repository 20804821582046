/*#

The Media component is used for splitting content into a fixed width column and a fluid column that works nicely with for different screen widths.

Based on the Flag object by CSS Wizardry, but renamed to Media because it's more common name for this layout.
http://csswizardry.com/2013/05/the-flag-object/

<div class="Media Media--top">
    <div class="Media-side">
        <img src="../../assets/images/temp/bmw1.jpg"  width="100"  />
    </div>
    <div class="Media-body">
        <p>This is the body of the Media object. You can change the alignment of the body to be either middle (default), top or bottom.</p>
        
        <div class="Media Media--top">
            <div class="Media-side">
                <img src="../../assets/images/temp/bmw1.jpg" width="100" />
            </div>
            <div class="Media-body">
                <p>Nested Media object works as well.</p>
            </div>
        </div>
    </div>
</div>
*/

@import "../../common/variables";

@Media-gutter: @spaceMedium;

.Media {
    display: table;
    width: 100%;
}

.Media-side,
.Media-body {
    display: table-cell;
    vertical-align: middle;

    .Media--top & {
        vertical-align: top;
    }

    .Media--bottom & {
        vertical-align: bottom;
    }
}

.Media--right {
    .Media-side {
        display: table-cell;
    }
}

.Media-side {
    padding-right: @Media-gutter;

    > img {
        display: block;
        max-width: none;
    }

    .Media--right & {
        padding-right: 0;
        padding-left: @Media-gutter;
    }  
}

.Media-body {
    width: 100%;
    
    .Media--equal & {
        width: auto;
    }
}