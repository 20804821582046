/**
 * Blocks
 */
#app *{
    position: relative;
}

#app .block {
    padding: 40px 60px 0 60px;

    background: rgb(245,245,245); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(245,245,245,1) 95%, rgba(233,233,233,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(95%,rgba(245,245,245,1)), color-stop(100%,rgba(233,233,233,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(245,245,245,1) 95%,rgba(233,233,233,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(245,245,245,1) 95%,rgba(233,233,233,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(245,245,245,1) 95%,rgba(233,233,233,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(245,245,245,1) 95%,rgba(233,233,233,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#e9e9e9',GradientType=0 ); /* IE6-9 */
}

#app .block.block-white {
    background: rgb(255,255,255); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 95%, rgba(233,233,233,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(95%,rgba(255,255,255,1)), color-stop(100%,rgba(233,233,233,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 95%,rgba(233,233,233,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(255,255,255,1) 95%,rgba(233,233,233,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(255,255,255,1) 95%,rgba(233,233,233,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(255,255,255,1) 95%,rgba(233,233,233,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e9e9e9',GradientType=0 ); /* IE6-9 */
}

#app .block .content {
    height: 100%;
    padding-top: 40px;
}

#block-1 {
    height: 630px;
}

#block-2 {
    height: 600px;
}

#block-3 {
    height: 650px;
}

#block-4 {
    height: 650px;
}

#block-1 .content,
#block-3 .content {
    padding-left: 440px;
}

#block-2 .content,
#block-4 .content {
    padding-right: 440px;
}

#block-1 img,
#block-3 img {
    position: absolute;
    bottom: 0;
    left: -10px;
}

#block-2 img,
#block-4 img {
    position: absolute;
    bottom: 0;
    right: -10px;
}

#block-5 {
    text-align: center;
}

#block-5 img {
    margin: 40px 0 20px 0;
}

/**
 * Typography
 */
#app h1 {
    border: 0;
    margin: 0 0 10px 0;

    font-size: 40px;
    font-weight: bold;
    text-transform: none;
}

#app h2 {
    margin-bottom: 30px;

    font-size: 30px;
    font-weight: bold;
}

#app p {
    margin-bottom: 10px;

    font-size: 16px;
}

#app p.discrete {
    margin-top: -5px;

    color: #999;
    font-size: 13px;
    font-style: italic;
}

#app ul li {
    height: 20px;
    margin-bottom: 5px;
    list-style: none;
    background: url(../img/app/tick.png) left center no-repeat;

    line-height: 20px;
}