/*#

Styling for green card image info elements.

Examples:
<div style="max-width:300px; position:relative;">
    <div class="Card-ImageFrame">
            <div class="Card-image">
                <img src="/assets/images/temp/priusCard.jpg">
            </div>
         <div class="ImageInfoBox ImageInfoBox--border">
            <div class="ImageInfoBox-date"><span>7 okt 19:14</span></div>
            <div class="ImageInfoBox-moreImages">
                <a href="#">Se alla</a>
                <i class="Icon Icon--pictures"></i>
            </div>
         </div>
    </div>
</div>
In row dispaly
<div style="max-width:208px; position:relative;">
     <div class="Card-ImageFrame">
            <div class="Card-image">
                <img src="/assets/images/temp/priusCard.jpg">
            </div>
    <div class="ImageInfoBox ImageInfoBox--row">
        <div class="ImageInfoBox-date"><span>7 okt 19:14</span></div>
        <div class="ImageInfoBox-moreImages">
        <a href="#">Se alla</a>
        <i class="Icon Icon--pictures"></i>
        </div>
    </div>
       </div>
</div>

*/

@import "../../common/variables.less";

.ImageInfoBox {
    width: 100%;
}

.ImageInfoBox--border {
    .ImageInfoBox-date {
        bottom: 1px;
    }
}

.ImageInfoBox-date {
    background: @colorLightGreen;
    position: absolute;
    bottom: 0px;
    left: 10px;
    height: 25px;

    span {
        color: #fff;
        padding: @spaceXSmall 1em;
        font-size: @textSizeSmall;
    }

}

.ImageInfoBox-moreImages {
}

.ImageInfoBox {
    a {
        position: absolute;
        right: 35px;
        font-size: @textSizeSmall;
        font-weight: 300;
        bottom: 5px;
    }

    .Icon--pictures {
        background: @colorLightGreen;
        color: #fff;
        position: absolute;
        bottom: 4px;
        right: 0;
        height: 25px;
        padding: 4px 5px 5px;
    }

    .Icon--pictures:before {
        font-size: 20px;
        display: block;
    }
}

.ImageInfoBox--row {
    .ImageInfoBox-date {
        left: 10px;
        bottom: 10px;

        @media @bpSmall {
            left: 0;
            bottom: 0;
        }

    }


    .Icon--pictures {
        bottom: 0px;
        right: 0;
        height: auto;
    }
}
