@import (reference) "../common/variables.less";

/**
* Text Font Family
*/

.u-textOpenSansCond{
    font-family: @fontFamilyTertiary !important;
}

.u-textMuseo_sans{
    font-family: @fontFamilyPrimary !important;
}

/**
 * Text font size.
 */

.u-textXXXSmall {
    font-size: @textSizeXXXSmall !important;
}
.u-textXXSmall {
    font-size: @textSizeXXSmall !important;
}
.u-textXSmall {
    font-size: @textSizeXSmall !important;
}
.u-textSmall {
    font-size: @textSizeSmall !important;
}
.u-textMedium, 
.u-textNormal {
    font-size: @textSizeMedium !important;
}
.u-textLarge {
    font-size: @textSizeLarge !important;
}
.u-textXLarge {
    font-size: @textSizeXLarge !important;
}
.u-textXXLarge {
    font-size: @textSizeXXLarge !important;
}
.u-textXXXLarge {
    font-size: @textSizeXXXLarge !important;
}

.u-textBig {
    font-size: @textSizeBig !important;
}

// Text font size for breakpoint bpSmall
.u-sm-textXSmall {
    @media @bpSmall{
        font-size: @textSizeXSmall !important;
    }
}
.u-sm-textSmall {
    @media @bpSmall{
        font-size: @textSizeSmall !important;
    }
}
.u-sm-textMedium {
    @media @bpSmall{
        font-size: @textSizeMedium !important;
    }
}
.u-sm-textLarge {
    @media @bpSmall{
        font-size: @textSizeLarge !important;
    }
}

// Text font size for breakpoint bpMedium
.u-md-textXSmall {
    @media @bpMedium{
        font-size: @textSizeXSmall !important;
    }
}
.u-md-textSmall {
    @media @bpMedium{
        font-size: @textSizeSmall !important;
    }
}
.u-md-textMedium {
    @media @bpMedium{
        font-size: @textSizeMedium !important;
    }
}
.u-md-textLarge {
    @media @bpMedium{
        font-size: @textSizeLarge !important;
    }
}

/**
 * Text font weights
 */

.u-textWeightThin {
    font-weight: 100 !important;
}
.u-textWeightLight {
    font-weight: 300 !important;
}
.u-textWeightNormal {
    font-weight: normal !important;
}
.u-textWeightMedium {
    font-weight: 500 !important;
}
.u-textWeightBold {
    font-weight: 700 !important;
}

u-textWeightXBold {
    font-weight: 900 !important;
}

/**
 * Word breaking
 *
 * Break strings when their length exceeds the width of their container.
 */

.u-textBreak {
  word-wrap: break-word !important;
}

/**
 * Horizontal text alignment
 */

.u-textCenter {
    text-align: center !important;
}

.u-textLeft {
    text-align: left !important;
}

.u-textRight {
    text-align: right !important;
}

/**
 * Prevent whitespace wrapping
 */

.u-textNoWrap {
    white-space: nowrap !important;
}

/**
 * change text transform
 */

.u-textUpper {
    text-transform:uppercase;
}

/* line-height*/
.u-lineHeightNormal{
    line-height: 1em !important;
}

.u-lineHeightMedium{
    line-height: 1.4em !important;
}


/**
 * Text truncation
 *
 * Prevent text from wrapping onto multiple lines, and truncate with an
 * ellipsis.
 *
 * 1. Ensure that the node has a maximum width after which truncation can
 *    occur.
 * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
 *    nodes.
 */

.u-textTruncate {
    max-width: 100%; /* 1 */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important; /* 2 */
}
.u-textTruncate3{
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    height: 50px;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}
/**
* ms IE 11 fix
*/
.u-ms-flex-negative-1 {
    -ms-flex-negative: 1 !important;
}

/**
* Custom Css
*/
.u-textIndentz{
    text-indent: 0 !important;
}

.u-overflowHidden{
    overflow: hidden !important;
}

.u-circleBlue {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  font-size: 1.5em;
  color: #fff;
  background-color: #1AB0ED;
  line-height: 1.5em;
  text-align: center;
  border: 2px solid #fff;
}