/*#
Go to https://icomoon.io and upload youre JSON file

Icons
<div style="width:210px; display:inline-block;">
   <span class="Icon Icon--pictures"></span>
</div>
*/

@import (reference) "../../common/variables.less";

/** VARIABLES FROM ICOMOON */

@icomoon-font-path: "/assets/styles/fonts/icons";

@Icon--caravan: "\e900";
@Icon--atv: "\e901";
@Icon--transport: "\e902";
@Icon--car: "\e909";
@Icon--rv: "\e90c";
@Icon--cross: "\2613";
@Icon--sort-down: "\25bc";
@Icon--sort-up: "\25b2";
@Icon--save-star-filled: "\2605";
@Icon--save-star: "\2606";
@Icon--save-heart: "\2661";
@Icon--save-heart-filled: "\2764";
@Icon--sort-mobile: "\1f53d";
@Icon--pictures: "\1f697";
@Icon--arrow-right: "\25b6";
@Icon--arrow-down: "\e90a";
@Icon--arrow-up: "\e90b";
@Icon--arrow-left: "\25c0";
@Icon--search: "\1f50d";
@Icon--tab-mob: "\e912";
@Icon--gasoline: "\e903";
@Icon--diesel: "\e904";
@Icon--electric: "\e905";
@Icon--etanol: "\e906";
@Icon--hybrid: "\e907";
@Icon--naturgas: "\e908";
@Icon--menu: "\2630";
@Icon--tabMosaic: "\e910";
@Icon--tabRow: "\e911";
@Icon--checkmark: "\2611";
@Icon--mobile: "\e90d";
@Icon--envelop: "\e90e";



/** STYLE.LESS FROM ICOMOON */

@font-face {
    font-family: 'BilwebIcons';
    src:    url('@{icomoon-font-path}/BilwebIcons.eot?q1oy24');
    src:    url('@{icomoon-font-path}/BilwebIcons.eot?q1oy24#iefix') format('embedded-opentype'),
        url('@{icomoon-font-path}/BilwebIcons.ttf?q1oy24') format('truetype'),
        url('@{icomoon-font-path}/BilwebIcons.woff?q1oy24') format('woff'),
        url('@{icomoon-font-path}/BilwebIcons.svg?q1oy24#BilwebIcons') format('svg');
    font-weight: normal;
    font-style: normal;
}

.Icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'BilwebIcons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Icon--caravan {
    &:before {
        content: @Icon--caravan;
    }
}
.Icon--atv {
    &:before {
        content: @Icon--atv;
    }
}
.Icon--transport {
    &:before {
        content: @Icon--transport;
    }
}
.Icon--car {
    &:before {
        content: @Icon--car;
    }
}
.Icon--rv {
    &:before {
        content: @Icon--rv;
    }
}
.Icon--cross {
    &:before {
        content: @Icon--cross;
    }
}
.Icon--sort-down {
    &:before {
        content: @Icon--sort-down;
    }
}
.Icon--sort-up {
    &:before {
        content: @Icon--sort-up;
    }
}
.Icon--save-star {
    &:before {
        content: @Icon--save-star;
    }
}
.Icon--save-heart {
    &:before {
        content: @Icon--save-heart;
    }
}
.Icon--save-heart-filled {
    &:before {
        content: @Icon--save-heart-filled;
    }
}
.Icon--save-star-filled {
    &:before {
        content: @Icon--save-star-filled;
    }
}
.Icon--sort-mobile {
    &:before {
        content: @Icon--sort-mobile;
    }
}
.Icon--pictures {
    &:before {
        content: @Icon--pictures;
    }
}
.Icon--arrow-right {
    &:before {
        content: @Icon--arrow-right;
    }
}
.Icon--arrow-down {
    &:before {
        content: @Icon--arrow-down;
    }
}
.Icon--arrow-up {
    &:before {
        content: @Icon--arrow-up;
    }
}
.Icon--arrow-left {
    &:before {
        content: @Icon--arrow-left;
    }
}
.Icon--search {
    &:before {
        content: @Icon--search;
    }
}
.Icon--tab-mob {
    &:before {
        content: @Icon--tab-mob;
    }
}
.Icon--gasoline {
    &:before {
        content: @Icon--gasoline;
    }
}
.Icon--diesel {
    &:before {
        content: @Icon--diesel;
    }
}
.Icon--electric {
    &:before {
        content: @Icon--electric;
    }
}
.Icon--etanol {
    &:before {
        content: @Icon--etanol;
    }
}
.Icon--hybrid {
    &:before {
        content: @Icon--hybrid;
    }
}
.Icon--naturgas {
    &:before {
        content: @Icon--naturgas;
    }
}
.Icon--menu {
    &:before {
        content: @Icon--menu;
    }
}
.Icon--tabMosaic {
    &:before {
        content: @Icon--tabMosaic;
    }
}
.Icon--tabRow {
    &:before {
        content: @Icon--tabRow;
    }
}
.Icon--checkmark {
    &:before {
        content: @Icon--checkmark;
    }
}
.Icon--mobile {
    &:before {
        content: @Icon--mobile;
    }
}
.Icon--envelop {
    &:before {
        content: @Icon--envelop;
    }
}
.fix-position-envelop {
    position: relative;
    top: 1px;
    left: -4px;
}
.fix-position-mobile {
    position: relative;
    top: 1px;
    left: -6px;
}

/** Bilweb specified classes */

.iconText {
    font-size: @textSizeXXXXSmall;
    text-transform: uppercase;
    text-align: center;
    margin:auto;

    @media @bpSmall {
        font-size: @textSizeXXSmall;
    }
}

.IconCheckmark--green {
    color: @colorLightGreen !important;
}
