@import (reference) "../common/variables.less";

/**
 * Add classes for common colors.
 */

.u-colorText {
	color: @colorText;
}

.u-colorTextLight {
	color: @colorTextLight;
}
.u-colorTextLighter {
    color: @colorTextLighter;
}

.u-colorTextGrey {
    color: @colorTextGrey !important;
}
.u-colorTextBlack {
    color: @colorTextBlack;
}
.u-colorTextYellow {
    color: @colorTextYellow;
}
.u-colorTextGreen {
    color: @colorLightGreen !important;
}

.u-colorTextwhite {
    color: #fff;
}

.u-colorTextRed {
    color: @colorTextRed;
}
// Examples Text Colors: 

.u-colorWhite {
	color: #fff !important;
}
// 
 .u-colorBlue {
 	color: #1AB0ED !important;
 }
// 
// .u-colorRed {
// 	color: @colorRed !important;
// }

// Example Background Colors: 

.u-colorBgRed {
	
}

.u-colorBgGrey0 {
    background-color: @colorGrey0 !important;
}
.u-colorBgGrey3 {
    background-color: @colorGrey3 !important;
}
.u-colorBgGrey4 {
    background-color: @colorGrey4 !important;
}

.u-colorBgGrey {
	background-color: @colorGrey1;
}

.u-colorBgWhite {
    background: #fff!important;
}

.u-colorBgGreen {
    background-color: @colorLightGreen;
}

.u-colorBgLighterGreen {
    background-color: @colorLighterGreen;
}

.u-colorBgRed {
    background-color: #b33528;
}

.u-colorBgSteelBlue {
    background-color: #81889B !important;
}
.u-colorBgBlue {
    background-color: @colorButton !important;
}
.u-colorBgPureRed {
    background-color: #ff0000;
}