/**
 * Photos
 */
.photos-grid {
    margin-bottom: 40px;
}

.photos-grid li {
    max-width: 200px;
    max-height: 200px;
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative;

    overflow: hidden;
    float: left;

    cursor: pointer;
}

.photos-grid .delete {
    width: 30px;
    height: 30px;
    top: 10px;
    right: 10px;

    display: none;
    position: absolute;

    background: #fff;
    text-align: center;
    line-height: 29px;
    border-radius: 50%;
    font-weight: bold;

    @media only screen and (max-width: 425px) {
        display: block !important;
    }
}

.photos-grid .deletei {
    width: 24px;
    height: 24px;
    margin: -265px 0 0 238px;

    display: none;
    position: absolute;

    background: #fff;
    text-align: center;
    line-height: 24px;
}

.photos-grid img {

    box-shadow: 0 1px 1px rgba(0, 0, 0, .3);
}

.photos-grid .upload {
    border-radius: 5px;
    border: 3px dashed #d8d8d8;

    background: url(../images/icons/photo.png) center center no-repeat;

    font-size: 13px;
    line-height: 180px;
    text-align: center;

    display: list-item;
    position: relative;

    width: 200px;
    height: 200px;
}

.photos-grid .upload.wide {
    width: 300px;
}

.photos-grid .upload.chosen {
    border-color: #000000;
}

.photos-grid .upload:hover {
    border-color: #282828;
}

.photos-grid .upload input {
    width: 100%;
    height: 100%;

    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
}

.loading_li {
    display: none;
}

.loading_li .loading {
    border: medium none;
    box-shadow: none !important;
    left: 70px;
    position: relative;
    top: 70px;
    display: block;
}