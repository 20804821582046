@import "../../common/variables.less";

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 5px;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  border: 1px solid #d8e2ef;
  border-radius: 50%;
  min-width: 31px;
}

.page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #1AB0ED;
  border-color: #fff;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(44, 123, 229, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(44, 123, 229, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
}

.page-item:last-child .page-link {
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1AB0ED;
  border-color: #fff;
}

.page-item.disabled .page-link {
  color: #748194;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #d8e2ef;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
}

.pagination-sm .page-item:last-child .page-link {
}