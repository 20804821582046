@import "../../common/variables.less";

.Text--align--center {
	text-align: center;
}

.sm-Text--align--center {
	text-align: center;
	@media @bpSmall {
        text-align: left;
    }
}

.Grey-trust-background {
	background-color:@colorGrey1;

	@media @bpXSmall {
		margin-left:-20px!important;
		margin-right:-20px !important;
	}

	@media @bpLarge {
		margin-left:0!important;
		margin-right:0!important;
	}

}
