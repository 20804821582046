@import "../../common/variables.less";

.goToNews{
    cursor: pointer !important;
}

.mob-heightFix {
	height: 100%;
    @media @bpMedium {
        height: auto;
    }
}

.mob-displayFix.u-flex {
	
    @media @bpMedium {
           display: block !important;
    }
}

#loading{
	display: none;
}

.cursorPointer{
  cursor: pointer;
}

.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 450px;
  margin: 20px auto;
}

.terms-popup {
  position: relative;
  background: #FFF;
  
  width: auto;
  max-width: 450px;
  margin: 20px auto;
  
}

.terms-popup .popup_content{
  height: 700px;
  overflow-y: scroll;
  padding: 20px;
}

.login-popup {
  position: relative;
  background: #FFF;
  
  width: auto;
  max-width: 500px;
  margin: 20px auto;
  
}

.login-popup .popup_content{
  height: auto;
  padding: 20px;
}

.object-via-email-popup {
  position: relative;
  background: #FFF;
  
  width: auto;
  max-width: 700px;
  margin: 20px auto;
  
}


.object-via-email-popup .popup_content{
  height: auto;
  padding: 20px;
}

.author-img {
  position: absolute;
  right: 1.125em;
  bottom: -3em;
  max-width: 6em;
  border: 2px solid #fff;
}

.coworker-img {
  max-width: 6em;
  border: 2px solid #fff;
}


ul li i.Icon.Icon--checkmark{
  margin-left: -1.3em;
  width: 1.3em;
  display: inline-block;
}

.static-page ul {
  font-size: @textSizeSmall;
}

.static-page a {
  font-size: @textSizeMedium;
}
.static-page .side-nav a{
  font-size: @textSizeSmall;
}

.static-page h2 {
  margin: 0;
}

.ol-pages{
  font-size: 0.875em;
}

.u-foldTopRight {
  position: relative;
}

.u-foldTopRight:after{
    content: "";
    position: absolute;
    top: 0%;
    right: 0%;
    width: 0px;
    height: 0px;
    border-top: 30px solid #fff;
    border-left: 30px solid transparent;
}
.u-foldTopRight:before{
  content: "";
  position: absolute;
  top: 0%;
  right: 0%;
  width: 0px;
  height: 0px;
  border-bottom: 31px solid #DDDDDD;
  border-right: 31px solid transparent;
}

.u-custome-select {
  #selectbox--focus{
    z-index: -110;
  }

  #selectbox--focus{
    cursor: pointer !important;
  }

  .mdl-menu__container {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
}

.u-heightAuto {
  height: auto !important;
}

.auction_list_res_fix2{
  display: none;
}

@media only screen and (max-width: 543px), print {
  .auction_list_res_fix2{
      display: none !important;
    }
}

@media only screen and (max-width: 1000px), print {
  .auction_list_res_fix {
    display: none;
  }
  .auction_list_res_fix2 {
    display: block;
  }
}

.u-overflowVisible{
  overflow: visible !important;
}
.news_mobile_content p, .news_mobile_content a, .news_mobile_content ul{
  font-size: .875em!important;
}

.language_menu .mdl-menu__item{
  height: 35px;
  line-height: 35px;
}