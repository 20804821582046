/*#

Styling for links and button elements.
Button Group

Horisont
<div class="Button--horisont">
    <a href="#" class="Button Button--square">Compare<i class="Icon Icon--checkmark u-marginLxsm"></i></a>  
    <a href="#" class="Button Button--squareGreen">Link Button</a>  
</div> 
 
ButtonSplit 50% 
<div class="Button--horisont">
    <a href="#" class="Button Button--square Button--Split">Compare<i class="Icon Icon--checkmark u-marginLxsm"></i></a>  
    <a href="#" class="Button Button--squareGreen Button--Split">Link Button</a>  
</div>     
  
Vertical 
<div class="Button--vertical ">
    <a href="#" class="Button Button--square">Compare<i class="Icon Icon--checkmark u-marginLxsm"></i></a>  
    <a href="#" class="Button Button--squareGreen">Link Button</a>  
</div> 
*/

@import "../../common/variables.less";

.Button--horisont {
    .Button {
        &:last-child {
            margin-left: -0.3em;
        }
    }
}



.Button--vertical {
     .Button {
         display:block;
        &:last-child {
            
        }
    }
}

.Button--Split {
    width:50%;
}
.Button--adjustSize {
    width: 100%;
    margin-left:0!important;
    @media @bpXSmall {
        width:50%;
        margin-left: -0.3em!important;
    }
}