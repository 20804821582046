/*#

Give a short description of the component's use case and its options.
Markdown is allowed for rich editing.

Examples:

<div>
    <!-- replace this comment with example HTML -->
</div>

*/

@import "../../common/variables.less";

.Page {
    padding: 0;
}

.Page-container {
    max-width: @widthContainer;
    margin-left: auto;
    margin-right: auto;
}

.Page-main {
    padding: 0;

    @media @bpMedium {
    	padding: 0 1em;
    }
}


/* css for staff page */
.Staff_header {
    background-color: #f9f9f9;
    padding: 25px;
    display: flex;
    align-items: center;
}
.Staff_profile-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 4px solid #ffffff;
    margin-right: 20px;
}
.Staff_header-info h1 {
    margin: 0;
    font-size: 28px;
}
.Staff_header-info h2 {
    margin: 5px 0;
    font-size: 18px;
    font-weight: normal;
}
.Staff_header-info a {
    text-decoration: none;
}
.Staff_header-info a:hover {
    text-decoration: underline;
}
.Staff_content {
    padding: 20px;
}
.Staff_content h3 {
    color: #1ab0ed;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 5px;
    margin-bottom: 15px;
    font-size: 20px;
}
.Staff_content p {
    line-height: 1.6;
}
.Staff_content ul {
    list-style: none;
    padding: 0;
}
.Staff_content li {
    background: #f9f9f9;
    margin-bottom: 8px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #e6e6e6;
}
/* END OF - css for staff page */