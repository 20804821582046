.page-content {
	.u-paddingAlg;
}

.history-page {
	img {
		-webkit-box-shadow: -1px -1px 34px 0px rgba(0,0,0,0.57);
		-moz-box-shadow: -1px -1px 34px 0px rgba(0,0,0,0.57);
		box-shadow: -1px -1px 34px 0px rgba(0,0,0,0.57);
		.u-marginAmd;
		.u-paddingAsm;
	}
	h3 {
		.u-marginTmd;
	}
	h1 {
		line-height: 1.1;
	}
}

.u-sm-paddingRmd {
	.u-paddingRz;
	@media @bpSmall {
        .u-paddingRmd;
    }
}

.u-sm-paddingLmd {
	.u-paddingLz;
	@media @bpSmall {
        .u-paddingLmd;
    }
}