@import "../../common/variables.less";

.slider_nav {
	position: absolute;
	top: 42%;
	width: 100%;

	button {
		width: 2em;
		height: 4em;
		border: none;
		background: rgba(255, 255, 255, 0.35) !important;

		i {
			font-size: 2em;
		}

		&.left {
			border-bottom-right-radius: 8em;
			border-top-right-radius: 8em;

			i {
				margin-top: .3em;
				margin-left: -.3em;
			}
		}

		&.right {
			border-bottom-left-radius: 8em;
			border-top-left-radius: 8em;
			position: absolute;
			right: 0px;

			i {
				margin-top: .2em;
				margin-left: -.05em;
			}
		}
	}
}

.show_media_chips {
	bottom:10px;
}
.show_media_chips span {
	float: left !important;
	padding: 0.55em .7em;
	border-radius: 4px;
	margin-left: 10px;
}

.author_pic, .author_pic_small {
	top: -2.5em;
	width: 6.5em;
	height: 6.5em;
	padding: 0.72em;
	border: 2px solid #fff;
}
.author_pic_small {
	top: -1.4em;
	width: 4.5em;
	height: 4.5em;
	padding: 0.5em;
}

.valuator {
	margin-top: -2.5em;
	max-width: 5em;
	border: 2px solid #fff;

	@media @bpMedium {
		position: relative;
		top: -1.8em;
		margin-top: 0;
	}
}

.pos_fix.u-posRelative, .pos_fix.u-posAbsoluteRight {
	@media @max-bpMedium {
		position: static !important;
	}
}
.shadow_fix.mdl-shadow--2dp{
	@media @max-bpMedium {
		box-shadow: none !important;
	}
}

.font_fix.u-textSmall {
	font-size: 1.125em !important;
}

.mob-paddingHsm {
	@media @max-bpMedium {
		padding-left: @spaceSmall !important;
		padding-right: @spaceSmall !important;
	}
}

li.slider_height_fix {
	max-height: 507px; 
	text-align: center;

	img {
		@media @max-bpMedium {
			
		}
	}
}
.gallery-close {
	color: #999;
    cursor: pointer;
    float: right;
    height: 37px !important;
    line-height: 17px !important;
    padding: 10px 0;
    text-align: center;
    width: 40px !important;
    text-decoration: none !important;
    outline: medium none;
    transition: color 0.2s linear;
}

.object_view {
	#loader_holder {
		text-align: center;
		padding: 2em;
	}

	sup {
		top: -.27em;
		left: 0.5em;
		font-size: 0.7em;
	}
	#bid_abort {
		font-size: 0.8em;
		font-weight: 300;
	}
}

#bid_history {
	span.user_bid {
		background-color: @colorButton !important;
		color: #fff !important;
	}
}

.valuator_send_message{
	@media @max-bpMedium {
		font-size: 1em;
	}
}