/*#

Styling for Objectbanner.

Examples:
<div style="width:1024px">
<div class="Objectbanner">
    <div class="objectbanner-overlay"></div>
    <div class="blur" style="background-image: url(/assets/images/temp/bmw1.jpg); height: 100%;"></div>
    <div class="objectbanner-content">
        <h1 class="objectbanner-header u-hidden u-lg-block">BMW X3 2,0 d x-drive drag Svensks�ld 2007, SUV</h1>
        <div class="Grid">
            <div class="Grid-cell u-sm-size2of3">
                <div class="u-posRelative">
                    <img id="largeImg" src="../../assets/images/temp/bmw1.jpg" />
                    <div class="ImageInfoBox">
                        <div class="ImageInfoBox-date"><span>7 okt 19:14</span></div>
                    </div>
                </div>
            </div>
            <div class="Grid-cell u-size1of2 u-sm-size1of3 u-paddingRlg u-paddingLmd">
                <p class="u-colorTextwhite u-marginTz">F�rdelar med att k�pa bilen av Bilweb�s anslutna bilhandlare</p>
                <div class="Trust Trust--noBg u-paddingAsm Trust--inline">
                    <div class="Tust-imageWrapper">
                        <img class="Trust-image" src="/assets/images/professionella_icon.svg" width="43">
                    </div>
                    <h3 class="Trust-heading u-textXSmall u-colorTextwhite">Professionella</h3>
                </div>
                <div class="Trust Trust--noBg u-paddingAsm Trust--inline">
                    <div class="Tust-imageWrapper">
                        <img class="Trust-image" src="/assets/images/testade_icon.svg" width="43">
                    </div>
                    <h3 class="Trust-heading u-textXSmall u-colorTextwhite">Testade</h3>
                </div>
                <div class="Trust Trust--noBg u-paddingAsm Trust--inline">
                    <div class="Tust-imageWrapper">
                        <img class="Trust-image" src="/assets/images/garanti_icon.svg" width="43">
                    </div>
                    <h3 class="Trust-heading u-textXSmall u-colorTextwhite">Garanti</h3>
                </div>
                <div class="Trust Trust--noBg u-paddingAsm Trust--inline">
                    <div class="Tust-imageWrapper">
                        <img class="Trust-image" src="/assets/images/inga_dolda_icon.svg" width="43">
                    </div>
                    <h3 class="Trust-heading u-textXSmall u-colorTextwhite">Inga dolda avgifter</h3>
                </div>
                <div class="Trust Trust--noBg u-paddingAsm Trust--inline">
                    <div class="Tust-imageWrapper">
                        <img class="Trust-image" src="/assets/images/skuldfri_icon.svg" width="43">
                    </div>
                    <h3 class="Trust-heading u-textXSmall u-colorTextwhite">Skuldfri</h3>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
*/

@import "../../common/variables.less";
.Objectbanner {
    position: relative;
    height:100%;
    overflow: hidden;
}

.objectbanner-overlay {
   background:rgba(0,0,0,0.2);
   position:absolute;
   width:100%;
   height:100%;
   max-height:1000px;
   z-index:1;
}

.blur {
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-filter: blur(30px);
    -moz-filter: blur(30px);
    -o-filter: blur(30px);
    -ms-filter: blur(30px);
    filter: blur(30px);
    filter: blur(30px) grayscale(.5) opacity(0.8);
    filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='30');
    background-position:50% 50%;
    overflow:hidden;
    position:absolute;
    width:100%;
    clip: rect(0px, 1200px, 900px, 0px);
    max-height: 9000px;
}

.objectbanner-content {
     position:relative;
         z-index:10;
    @media @bpMedium {
        padding: 1em 0.5em;

        .Trust-image {
            width:35px;
        }
    }

    @media @bpLarge {
        padding:1.7em @spaceXLarge @spaceXLarge;
         .Trust-image {
            width:43px;
        }
    }

}


.objectbanner-header {
    font-weight: 900;
    font-size: @Heading-size3;
    color: #fff;
    line-height: 37px;
}


/* BMW Selection */
.objectbanner-bmwText {

    padding:3px;
    width:160px!important;
    background-color:#000;
    background: rgba(0, 0, 0, 0.55);
    width:100%;
    text-align: center;

    @media @bpMedium {
        position:absolute;
        left:10px;
        top:8px;
    }

}
.objectbanner-bmwText a{
    font-size: @textSizeSmall;
    color: #fff;
}




.alt-objectbanner {
    font-size: 1.475em;
    font-weight: normal;
    color: black;
    line-height: 1;
}

.object-contentWrapper {

}


.objectContent {
    @media @bpMedium {
       padding: 0 @spaceMedium 0 @spaceXLarge!important;
    }
}
