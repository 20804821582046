/*#

Styling for PushArea alternatives.

Examples:
    Default
<div style="width:1024px;">
    <div class="PushArea" style="background-image: url(/assets/images/temp/carBg.jpg); height: 450px;">
    <div class="PushArea-textPlate">
        <div class="Grid">
            <div class="Grid-cell u-sm-size3of5">
                <h2 class="PushArea-heading">Bilweb är en av Sveriges storsta marknadsplatser for nya och begagnade bilar
                </h2>
                <p class="PushArea-text">Hos oss finner du de basta bilarna fran de basta bilhandlarna samt nyttig information kring bilkopet etc.</p>
            </div>
            <div class="Grid-cell u-sm-size2of5 u-posRelative">
                <div class="PushArea-secondArea u-textCenter PushArea-bottom PushArea-center">
                    <h3 class="PushArea-heading PushAreaHeading--md">Vänta inte!</h3>
                    <button class="Button Button--gradientLarge">Hitta din nya bil nu!</button>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

    Examples:
    Text area right

<div style="width:1024px;">
<div class="PushArea u-paddingAlg PushArea--right" style="background-image: url(/assets/images/temp/carBg.jpg); height: 450px;">
    <div class="PushArea-textPlate u-pullHlg u-paddingVmd  u-paddingHmd PushAreaTextPlate--small">
        <div class="Grid">
            <h2 class="PushArea-heading PushAreaHeading--small">Bilweb är en av Sveriges största marknadsplatser för nya och begagnade bilar
            </h2>
            <p class="PushArea-text PushAreaText--small">Fördelar med att köpa bilen av Bilweb's anslutna bilhandlare</p>
            <div class="Trust Trust--noBg u-paddingAsm">
                <div class="Tust-imageWrapper">
                    <img class="Trust-image" src="/assets/images/professionella_icon.svg" width="43">
                </div>
                <h3 class="Trust-heading u-textXSmall">Professionella</h3>
            </div>
            <div class="Trust Trust--noBg u-paddingAsm">
                <div class="Tust-imageWrapper">
                    <img class="Trust-image" src="/assets/images/testade_icon.svg" width="43">
                </div>
                <h3 class="Trust-heading u-textXSmall">Testade</h3>
            </div>
            <div class="Trust Trust--noBg u-paddingAsm">
                <div class="Tust-imageWrapper">
                    <img class="Trust-image" src="/assets/images/garanti_icon.svg" width="43">
                </div>
                <h3 class="Trust-heading u-textXSmall">Garanti</h3>
            </div>
            <div class="Trust Trust--noBg u-paddingAsm">
                <div class="Tust-imageWrapper">
                    <img class="Trust-image" src="/assets/images/inga_dolda_icon.svg" width="43">
                </div>
                <h3 class="Trust-heading u-textXSmall">Inga dolda avgifter</h3>
            </div>
            <div class="Trust Trust--noBg u-paddingAsm">
                <div class="Tust-imageWrapper">
                    <img class="Trust-image" src="/assets/images/skuldfri_icon.svg" width="43">
                </div>
                <h3 class="Trust-heading u-textXSmall">Skuldfri</h3>
            </div>

        </div>
    </div>

</div>
Example
<div style="width:1024px;">
<div class="PushArea" style="background-image: url(/assets/images/temp/carBg.jpg);">
    <div class="PushArea-textPlate u-paddingBsm">
        <div class="Grid">
            <div class="Grid-cell u-sm-size3of5 u-textCenter">
                <h2 class="PushArea-heading u-marginBz">Bilhandlare AB</h2>
                <p class="PushArea-text u-marginTz">Stockholm</p>
            </div>
            <div class="Grid-cell u-sm-size2of5 u-posRelative">
                <div class="PushArea-secondArea">
                    <a href="#" class="Button Button--gradient u-sizeFull u-marginBsm u-paddingVsm u-textXLarge">Ring oss</a>
                    <a href="mailto:info@bilweb.se" class="Button Button--transparant u-sizeFull u-paddingVsm u-textXLarge u-marginBmd">Maila oss</a>
                    <div class="Grid">
                        <div class="Grid-cell u-size1of3">
                            <span class="u-textSmall u-textWeightBold">Alla våra anläggningar</span>
                        </div>
                        <div class="Grid-cell  u-size2of3">
                            <select class="u-sizeFull u-paddingAsm">
                                <option>Byt anläggning</option>
                                <option>Västerås</option>
                                <option>Tärnaby</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    </div>

</div>
*/
@import "../../common/variables.less";

.PushArea *, .PushAreaDisplay * {
    color: #fff;
    text-align: center;
}

.PushArea {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-end;
    position: relative;
    padding: 0;
    margin: 0 -@spaceSmall;
    height: auto!important;
    min-height: 200px;


    @media @bpLarge {

        padding: @spaceLarge; margin: 0; height:350px!important;
    }
}

.PushArea--right {
    flex-direction: row-reverse;
}

.PushArea--small{
        @media @bpLarge {
            height:290px!important;
        }
}

.PushAreaDisplay {
    background-color:#666;
    @media @bpLarge {
        display: block!important;
    }
}

.PushAreaDisplayContent {
    background-color:#fff;
    padding:0;
    overflow:hidden;
    @media @bpContainer {
        background-color:#cecece;
        padding:21px 21px;
    }

}

.PushBenefitsDisplay {
    display:none!important;
    @media @bpMedium {
        display: block!important;
    }
}

.PushAreaSort {
    margin-top:48px;
}

/* ie fix */
.is-no-flexbox .PushArea--right .PushArea-textPlate {
    float: right;
}

.PushArea-heading {
    font-weight: 700;
    font-size: 26/16em;
    line-height: 26px;
    text-shadow: 0px 4px 5px rgba(0,0,0,0.50);

    @media @bpSmall {
        font-size: 30/16em; line-height: 34px;
    }

    @media @bpXLarge {
        font-size: 38/16em; line-height: 43px;
    }
}

.PushAreaHeading--small {
    font-size: 25/16em;
    line-height: 29px;
}

.PushAreaHeading--xsmall {
    font-size: 21/16em;
    line-height: 24px;
}

.PushHeadingDisplay {
    font-size: 19/16em;
    line-height: 27px;
    margin:0.7em 0 0.7em 0.7em;
    color:#fff;
    @media @bpLarge {
        font-size: 22/16em;
        margin:0.7em;
    }
    @media @bpXLarge {
        font-size: 24/16em;
    }
}

.PushAreaHeading--md {
    font-size: 24/16em;
    margin-bottom: @spaceXSmall;

    @media @bpSmall {
        font-size: 30/16em; line-height: 36px; margin-bottom: @spaceSmall;
    }
}

.PushArea-textPlate {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    position: relative;
    height: auto;
    padding: @spaceMedium @spaceMedium @spaceLarge @spaceLarge;
    margin-left: 0;
    text-align: center;

    @media @bpSmall {
        text-align:left;
    }

    @media @bpLarge {
        height:200px;
        padding: @spaceMedium @spaceMedium @spaceMedium  @spaceLarge;
        margin:200px 0 0 0;
        text-align:left;
    }
}


.PushArea-textPlate-welcome {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    position: relative;
    height: 100%;
    padding: @spaceMedium @spaceLarge @spaceLarge @spaceLarge;
    margin-left: 0;
    text-align: center;
    min-height: 200px;

    @media @bpSmall {
        text-align:left;
    }

    @media @bpLarge {
        height:auto;
        padding: @spaceMedium @spaceLarge @spaceMedium  @spaceLarge;
        margin:0 0 0 -@spaceLarge;
        text-align:left;
        width: 95%;
    }
    @media @bpXLarge {
        width: 100%;
   }
}

.PushArea-textPlate-list {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    position: relative;
    height: 100%;
    padding: @spaceMedium @spaceMedium @spaceLarge @spaceLarge;
    margin-left: 0;
    text-align: center;
    min-height: 200px;
    align-items: center;
    display:flex;

    @media @bpMedium {
        text-align:left;
        width: 65%;
    }

    @media @bpLarge {
        height:auto;
        padding: @spaceMedium @spaceMedium @spaceMedium  @spaceLarge;
        margin:0 0 0 -@spaceLarge;
        text-align:left;
        width: 65%;
        .u-pullHlg;
    }

     @media @bpXLarge {
         width: 60%;
    }
}
.PushAreaTextPlate--small {
    display: none;

    @media @bpLarge {
        width: 61%;
        display:block;
    }

     @media @bpXLarge {
         width: 50%;
    }
}

.PushAreaTextPlate--md {

    @media @bpLarge {
        width: 65%;
        display:block;
    }

     @media @bpXLarge {
         width: 80%;
    }
}



.PushArea-text {
    font-weight: 100;
    font-size: @textSizeXXLarge;
    line-height: 22px;
    width: 100%;

    @media @bpXSmall {
        line-height: 27px;
    }
}

.PushAreaText--md {
    font-size: @textSizeLarge;
    line-height: 21px;
}

.PushAreaText--small {
    font-size: @textSizeMedium;
    line-height: 19px;
}

.PushArea-secondArea {
}

.PushArea-bottom {
    @media @bpSmall {
        position:absolute;
        bottom:0px;
    }
}

/* ie fix*/
.is-no-flexbox .PushArea-bottom {
    position:relative;
    top:20px;
}
/**/

.PushArea-center {
    left: 0;
    right: 0;
}
