/*#
<div style="width:960px;">

    <div class="Slider owl-carousel owl-theme Slider--white u-alignCenter" data-module="slider" data-slider="">
    <div class="Slider-item">
        <img src='/assets/images/temp/bmw1.jpg' onclick='changeImage("../assets/images/temp/bmw1.jpg");'>
    </div>
    <div class="Slider-item">
        <img src='/assets/images/temp/bmw2.jpg' onclick='changeImage("/assets/images/temp/bmw2.jpg");'>
    </div>
    <div class="Slider-item">
        <img src='/assets/images/temp/bmw3.jpg' onclick='changeImage("/assets/images/temp/bmw3.jpg");'>
    </div>
    <div class="Slider-item">
        <img src='/assets/images/temp/bmw5.jpg' onclick='changeImage("/assets/images/temp/bmw5.jpg");'>
    </div>
    <div class="Slider-item">
        <img src='/assets/images/temp/bmw6.jpg' onclick='changeImage("/assets/images/temp/bmw6.jpg");'>
    </div>
    <div class="Slider-item">
        <img src='/assets/images/temp/bmw7.jpg' onclick='changeImage("/assets/images/temp/bmw7.jpg");'>
    </div>
    <div class="Slider-item">
        <img src='/assets/images/temp/bmw1.jpg' onclick='changeImage("/assets/images/temp/bmw1.jpg");'>
    </div>
    <div class="Slider-item">
        <img src='/assets/images/temp/bmw1.jpg' onclick='changeImage("/assets/images/temp/bmw1.jpg");'>
    </div>
    <div class="Slider-item">
        <img src='/assets/images/temp/bmw1.jpg' onclick='changeImage("/assets/images/temp/bmw1.jpg");'>
    </div>
    <div class="Slider-item">
        <img src='/assets/images/temp/bmw1.jpg' onclick='changeImage("/assets/images/temp/bmw1.jpg");'>
    </div>
    <div class="Slider-item">
        <img src='/assets/images/temp/bmw1.jpg' onclick='changeImage("/assets/images/temp/bmw1.jpg");'>
    </div>
</div>


</div>
*/
@import "../../common/variables.less";
.Slider {
    margin-bottom: @spaceMedium;
    @media @bpLarge {
        padding: @spaceSmall @spaceLarge 0 @spaceLarge;
        margin-bottom: @spaceLarge;
        min-height:125px;
    }
}
.Slider-item {
    position: relative;

    @media @bpMedium {
        margin:5px;
    }

    img {
        display: block;
        height: auto;
        width: 100%;
    }
}

#latest_tradein_objects .Slider-item{
    margin:10px;
}

.Slider-itemOverlay {
	background: rgba(0, 70, 173, 0.2);
	color: #fff;
	padding: 1em 0.5em 1.2em;
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
}

.Slider-itemOverlayHeading {
	color: #fff;
	margin: 0 0 0.2em;
	font-size: 1.375em;

	@media @bpMedium {
		font-size: 2em;
	}

	@media @bpXLarge, print {
		font-size: 2.875em;
		margin-bottom: 0;
	}
}

.Slider-itemOverlayLead {
	color: #fff;
	font-size: 1em;
	font-weight: 200;
	margin-bottom: 0;
	text-decoration: none;

	@media @bpMedium {
			font-size: 1.125em;
	}

	@media @bpXLarge, print {
			font-size: 1.375em;
	}
}

.Slider--pagingBelow {
	.owl-controls {
		position: static;
		text-align: center;

		.owl-pagination {
			background-color: rgba(0, 0, 0, 0.3);
			border-radius: 0;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			display: inline-block;
			padding: 0.2em 1em;

			.owl-page {
				display: inline-block;
				zoom: 1;
				*display: inline; /*IE7 life-saver */

				span {
					background: #869791;
					-webkit-border-radius: 20px;
					-moz-border-radius: 20px;
					border-radius: 20px;
					display: block;
					height: 12px;
					margin: 5px 7px;
					filter: Alpha(Opacity=50);/*IE7 fix*/
					opacity: 0.5;
					width: 12px;
				}
			}

			.owl-page.active span,
			.owl-page:hover span {
				background: #fff;
				filter: Alpha(Opacity=100);/*IE7 fix*/
				opacity: 1;
			}
		}
	}
}

.Slider--hidePaging {
	margin-bottom: -1px;

	.owl-controls {
		display: none !important;
	}
}

.Slider--full .Slider-item img {
	object-fit: cover;
	max-height: 550px;
}

.is-noObjectFit .Slider--full .Slider-item {
	img {
		max-height: none;
	}

	@media @bpXLarge, print {
		min-height: 500px;
		overflow: hidden;
		position: relative;

		img {
			bottom: -15%;
			position: absolute;
		}
	}
}

/* Prev & next buttons */
.owl-controls .owl-buttons div {
	color: @colorLink;
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
	margin: 5px;
	padding: 3px 10px;
	font-size: 22px;


	&:hover{
    color:@colorLinkHover;
	text-decoration: none;
	}
}

.Slider--white {
    background: #fff;
}
.owl-prev {
    position: absolute;
    left: 0;
    top:40%;
    opacity:0.7;
    @media @bpMedium {
        top: 42%;
        opacity:1;
    }

    &:before {
        content: "\25c0";
    }
}

.owl-next{
    position: absolute;
    right: 0;
     top:40%;
     opacity:0.7;
    @media @bpMedium {
        top:34%;
        opacity:1;
    }


     &:after {
        content: "\25b6";
    }

}


/* bx_slider css added by Ayaz*/

.bx-wrapper {
	position: relative;
	margin: 0;
	padding: 0;
	*zoom: 1;
}

.bx-wrapper img {
	max-width: 100%;
	display: block;
  margin: auto;
}

/** THEME
===================================*/

.bx-wrapper .bx-viewport {
	background: #aaa;

	/*fix other elements on the page moving (on Chrome)*/
	-webkit-transform: translatez(0);
	-moz-transform: translatez(0);
    	-ms-transform: translatez(0);
    	-o-transform: translatez(0);
    	transform: translatez(0);
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
	position: absolute;
	bottom: -30px;
	width: 100%;
}

/* LOADER */

.bx-wrapper .bx-loading {
	min-height: 50px;
	background: url(/assets/images/bx_slider/bx_loader.gif) center center no-repeat #fff;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
}

/* PAGER */

.bx-wrapper .bx-pager {
	text-align: center;
	font-size: .85em;
	font-family: Arial;
	font-weight: bold;
	color: #666;
	padding-top: 20px;
	bottom: 0px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
	display: inline-block;
	*zoom: 1;
	*display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
	background: #ccc;
	text-indent: -9999px;
	display: block;
	width: 10px;
	height: 10px;
	margin: 0 5px;
	outline: 0;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
	background: #333;
}

/* DIRECTION CONTROLS (NEXT / PREV) */

.bx-wrapper .bx-prev {
	left: 0;
	border-radius: 0 4.4em 4.4em 0;
    -moz-border-radius: 0 4.4em 4.4em 0;
    -webkit-border-radius: 0 4.4em 4.4em 0;

	&:before {
        content: "\25c0";
        font-size: 1.4em;
        padding-right: 0.4em;
    }
}

.bx-wrapper .bx-next {
	right: 0;
	border-radius: 4.4em 0 0 4.4em;
    -moz-border-radius: 4.4em 0 0 4.4em;
    -webkit-border-radius: 4.4em 0 0 4.4em;

	&:after {
        content: "\25b6";
        font-size: 1.4em;
        padding-left: 0.5em;
    }
}

.bx-wrapper .bx-prev:hover {
	background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
	background-position: -43px 0;
}

.bx-wrapper .bx-controls-direction a {
	position: absolute;
	top: 50%;
	background: @colorGrey1;
    color: @colorLink;
    text-align: center;
    border-radius-left: 50%;
    width: 2.2em;
    height: 4.4em;
    line-height: 4.4em;
    vertical-align: middle;
    margin-top: -2.2em;
    text-decoration: none;
}

.bx-wrapper .bx-controls-direction a:hover{
	color: @colorLinkHover;
	background: @colorGrey6;
}

.bx-wrapper .bx-controls-direction a.disabled {
	display: none;
}

/* AUTO CONTROLS (START / STOP) */

.bx-wrapper .bx-controls-auto {
	text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
	display: block;
	text-indent: -9999px;
	width: 10px;
	height: 11px;
	outline: 0;
	background: url(/assets/images/bx_slider/controls.png) -86px -11px no-repeat;
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
	background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
	display: block;
	text-indent: -9999px;
	width: 9px;
	height: 11px;
	outline: 0;
	background: url(/assets/images/bx_slider/controls.png) -86px -44px no-repeat;
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
	background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
	text-align: left;
	width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
	right: 0;
	width: 35px;
}

/* IMAGE CAPTIONS */

.bx-wrapper .bx-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	background: #666\9;
	background: rgba(80, 80, 80, 0.75);
	width: 100%;
}

.bx-wrapper .bx-caption span {
	color: #fff;
	font-family: Arial;
	display: block;
	font-size: .85em;
	padding: 10px;
}
