@import "../../common/variables.less";

.DealerContact {
    background: @colorGrey1;
    padding: @spaceMedium @spaceXLarge;
    width: 100%;

    @media @bpSmall {
        padding: @spaceSmall @spaceMedium @spaceMedium;
    }
}

.DealerContact-AdvantageIcons{
	width: auto !important;
}

.DealerContact--noBg {
    background: transparent;
}

.DealerContact-heading {
    text-align: center;
    font-size: @textSizeMedium;
}

.DealerContact-image {
    margin: 0 auto;
    display: block;
    height:100%;
}

.DealerContact-text {
    font-size: @textSizeSmall;
    margin: 0;
    text-align: center;

    @media @bpLarge {
        text-align:left;
    }
}

.Tust-imageWrapper {
    height: 65px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

/* for ie */
.is-no-flexbox .DealerContact-wrapper .DealerContact {
    min-height: 230px;

    .DealerContact-imageWrapper {
        display: inline;
        margin-top: @spaceMedium;

        .DealerContact-image {
            margin: @spaceMedium auto @spaceSmall;
        }
    }
}

.is-no-flexbox .objectbanner-content .DealerContact {
    margin-bottom: @spaceSmall;

    .DealerContact-image {
        margin-bottom: 0;
    }
}
/* End ie fixes*/

.DealerContact--inline {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: @spaceMedium;

    .DealerContact-imageWrapper {
        display: inline-block;
        height: auto;
    }

    .DealerContact-heading {
        text-align: left;
        display: inline-block;
        margin: 0 0 0 10px;
    }
}

.DealerContact-toggler {
    text-align: center;
    padding: @spaceSmall @spaceLarge 0;
    font-size: @textSizeLarge;
    line-height: 18px;

    &.is-active {
        background: @colorLightGreen;
        color: #fff;
        padding-bottom: @spaceSmall;
    }
}

.DealerContact-wrapper {
    display: none;
    margin: 0;

    &.is-active {
        display: block;
    }

    @media @bpSmall {
        margin: @spaceMedium 0;
    }

}

.DealerContact-bottomLine {
    border-bottom: 1px solid @colorLightGreen;
    margin: 0 0 @spaceMedium;
}

.DealerContact-bottomArrow {
    background: #fff;
    width: 15px;
    height: 15px;
    border-right: 1px solid @colorLightGreen;
    border-bottom: 1px solid @colorLightGreen;
    transform: rotate(45deg);
    margin: 0 auto;
    top: 8px;
    position: relative;

    &.is-active {
        display:block!important;
        border-right: 1px solid @colorLightGreen;
        border-bottom: 1px solid @colorLightGreen;
        transform: rotate(225deg);
    }
}