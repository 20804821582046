@import "../../common/variables.less";

.main-menu {
  .mdl-menu__item {
    height: 32px;
    line-height: 32px;
    font-size: @textSizeSmall;
    padding-left: 1.35em;
    padding-right: 1.35em;

    &:first-child {
      margin-top: .325em;
    }

    &:last-child {
      margin-bottom: .325em;
    }

    a {
      font-size: @textSizeMedium;
    }
  }
  .topMargin{
    margin-top: 1.3em;
  }
}