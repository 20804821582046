/*#

Styling for cards, example a car card.

Examples:
  Box display
 <div style="max-width:300px;">
    <div class="Card">
        <div class="Card-content">
            <h3 class="Card-heading">This is a card that you can fill</h3>
            <div class="Card-price">
                <p class="Card-mainPrice">224 900 kr</p>
                <span class="Card-monthPrice">(2321 kr/mån)</span>
            </div>
            <span class="Card-firm">Bilhandlarnamn, ort </span>
            <dl class="Card-carData">
                <dt>Mil:</dt>
                <dd>11 500</dd>
                <dt>Ar:</dt>
                <dd>2012</dd>
                <dt>Drivmedel:</dt>
                <dd><span class="fuelType">b</span>/<span class="fuelType">e</span></dd>
            </dl>
            <div>
                <span class="Card-network">MRF-ansluten handlare</span> <a href class="Card-history">Bilens historik (3)</a>
            </div>
        </div>
     </div>
</div>
Row display
<div style="max-width:945px;">
     <div class="Card">
        <div class="Grid">
             <div class="Grid-cell u-sm-size1of5">
                     <div class="Card-ImageFrame Card-ImageFrame--row">
                            <div class="Card-image">
                                <img src="/assets/images/temp/priusCard.jpg">
                            </div>
                         <div class="ImageInfoBox ImageInfoBox--row">
                            <div class="ImageInfoBox-date"><span>7 okt 19:14</span></div>
                            <div class="ImageInfoBox-moreImages">
                                <a href="#">Se alla</a>
                                <i class="Icon Icon--pictures"></i>
                            </div>
                         </div>
                    </div>
               </div>
               <div class="Grid-cell u-sm-size3of5">
                    <div class="Card-content Card-content--row">
                        <h3 class="Card-heading">Hyundai TUCSON Tucson 2.0 CRDi A6 4WD Premium</h3>
                        <span class="Card-firm">Bilhandlarnamn, ort </span>
                        <dl class="Card-carData Card-carData--row">
                            <dt>Mil:</dt>
                            <dd>11 500</dd>
                            <dt>Ar:</dt>
                            <dd>2012</dd>
                            <dt>Drivmedel:</dt>
                            <dd><span class="Icon Icon--gasoline"></span>/<span class="Icon Icon--etanol"></span></dd>
                        </dl>
                         <div class="Card-price Card-price--row">
                            <p class="Card-mainPrice Card-mainPrice-row">224 900 kr</p>
                            <span class="Card-monthPrice">(2321 kr/mån)</span>
                        </div>
                 </div>
             </div>
             <div class="Grid-cell u-sm-size1of5 u-posRelative">
                <div class="u-marginAsm">
                    <span class="Card-network Card-network--row">MRF-ansluten handlare</span> <a href class="Card-history Card-history--row">Bilens historik (3)</a>
                </div>
                <div class="Button--vertical">
                    <a href="#" class="Button Button--square">Compare<i class="Icon Icon--checkmark u-marginLxsm"></i></a>
                    <a href="#" class="Button Button--squareGreen">Button</a>
                </div>
            </div>

        </div>
     </div>
</div>
Trader card
 <div style="max-width:300px;">
    <div class="Card">
    <div class="Card-ImageFrame">
        <div class="Card-image CardImage--boarder">
            <img src="/assets/images/temp/logotype.jpg" />
        </div>
    </div>
    <div class="Card-content">
        <h3 class="Card-heading">Eklund och Franssen Bil AB i Upplands Vasby</h3>
        <span class="Card-firm">Ortsnamn</span>
        <div class="Card-inStock">
            <p class="Card-stockItem">1475</p>
            <span class="">bilar i lager</span>
        </div>
        <div>
            <span class="Card-network"><img class="u-marginRmd" src="/assets/images/temp/mrf-noText.png" />MRF-ansluten handlare</span>
        </div>
    </div>
    <div class="Button--horisont">
        <a href="#" class="Button Button--square Button--Split">Link</a>
        <a href="#" class="Button Button--squareGreen Button--Split">Se handlare</a>
    </div>
</div>
 </div>
*/
@import "../../common/variables.less";
.Card {
    width:100%;
    /*background: @colorGrey1;*/
     display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow:  column;
    -ms-flex-flow:  column;
    flex-flow:  column;
}
.Card-row {
    padding-bottom: 7px;
    padding-top: 3px;
    @media @bpSmall {
        padding: 0;
    }
}
.Card-Wrapper {
    max-width: 100%;
}
.Card-content {
     padding: 1.1em 1em;
     flex:auto;
    -webkit-flex: auto;
    -ms-flex-: auto;
}
.Card-ImageFrame {
       position:relative;
       -ms-flex-negative: 0;
}
.Card-DealerLogo {
	height: 13em;
	line-height: 13em;

	.Card-image {
		text-align: center;

		img{
			width: auto;
			max-height: 11em;
		}
	}
}

#dealer_list .Card-DealerLogo {
	height: 3.7em;
	line-height: 5.4em;

	@media @bpSmall {
		height: 7.5em;
		line-height: 7.5em;
	}

	@media only screen and (max-width: 419px), print {
		height: 3.2em;
		line-height: 4.3em;
	}

	.Card-image {
		img{
			width: 100%;
			height: auto;
		}
	}
}

.Card-image {
    width: 100%;
    height: 100%;

    img {
          width: 100%;
    }

    @media @bpSmall {
        padding-top: 0;
        padding-left: 0;
    }
}

.CardImage--boarder{
    border-bottom: 4px solid @colorLightGreen;
}
.Card-heading {
    font-size: @textSizeLarge;
    font-weight:700;
    color: @colorTextGrey;
    line-height: 18px;
    margin-bottom:0;
}
.Card-heading-row {
    padding: 5px 0 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Card-heading a {
    color: @colorTextGrey;
}

.Card-heading-green a {
    color: #32a845;
}
.Card-price, .Card-inStock {
    margin-bottom: @spaceSmall;
}
.Card-mainPrice, .Card-stockItem {
    color: @colorLightGreen;
    font-weight: 400;
    font-size: @textSizeMedium;
    display: inline-block;
    margin:0 2px 0 0;
    @media @bpXXSmall {
        font-size: @textSizeLarge;
    }
    @media @bpXSmall {
        margin:0 10px 0 0;
    }
}

.Card-stockItem{
      @media @bpXSmall {
        margin:0 2px 0 0;
    }
}

.Card-monthPrice {
    font-size: @textSizeMedium;
}

.Card-firm {
    font-size: @textSizeLarge;
    display:block;
}

.Card-carData {
    margin: 0;

    * {
        display: inline-block;
    }

    dt {
        font-size: @textSizeXSmall;
        font-weight: 900;
    }

    dd {
        font-size: 15px;
        margin-left: 2px;

        .Icon {
            color: @colorTextGrey;
        }
    }
}

.Card-network {
    font-size: @textSizeSmall;
}
.Card-history {
    font-size: @textSizeSmall;
}

/* row */

.Card-ImageFrame--row {
    display: inline-block;
    padding: 7px 0 10px 10px;

    @media @bpXSmall {
        padding: 7px 0 10px 10px;
    }

    @media @bpSmall{
        padding: 0;
    }

    img {
        vertical-align:bottom;
        width: 100%;
    }



}

.Card-content-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}



.Card-content--row {
    display: inline-block;
    padding: 4px @spaceSmall 0 @spaceSmall;
    line-height: 1.4em;

    @media @bpXXSmall {
        padding: @spaceSmall 0 @spaceSmall @spaceSmall;
        line-height: 1.5em;
        font-size: 15px;
    }

    @media @bpXSmall {
        padding: @spaceSmall 0 @spaceSmall @spaceMedium;
        line-height: 1.6em;
        font-size: 16px;
    }

    @media @bpSmall {
        height:100%
    }

    dt {
        font-size: 9px;
        font-weight: 900;

        @media @bpXSmall {
            font-size: @textSizeXSmall;
        }
    }

    dd {
        font-size: 8px;
        margin-left: 2px;
        margin-right: 0px;

        @media @bpXSmall {
            font-size: 15px;
            margin-right: 5px;
        }
    }

    .Card-price {
        margin-bottom: 0;
    }

}

.Card-carData--row {
    display:block;
    font-size: @textSizeSmall;
    line-height:1.4em;

    @media @bpXXSmall {
        font-size: @textSizeMedium;
    }
    @media @bpXXSmall {
        font-size: @textSizeMedium;
    }

}


.Card-price--row, .CardinStock--row,  .Card-content--row dl {
    display:inline-block;
    /*border-bottom: 1px solid @colorGrey2;*/
    width: 100%;

    @media @bpXSmall {
        border-bottom: none;
        width: auto;
    }
}

.Card-network--row, .Card-history--row {
    display: inline-block;
    text-align: left;
    margin-bottom: 2px;
    line-height: 16px;
    font-size:9px;
    font-size: @textSizeMedium;

    @media @bpMedium {
        display: block;
        text-align: right;

    }
}

.Card {
    .Button--vertical {
        width: 100%;
        position:relative;
        @media @bpXLarge {
            width:75%;
            right:0;
            position:absolute;
            bottom:0;
        }
    }
}

/* ie fix */

.is-no-flexbox .Card .Button--vertical {
    bottom:auto;
}

/**/

.Card-small {
    border-bottom: 1px solid @borderColor;
    margin-bottom: @spaceSmall;
    padding-bottom: @spaceSmall;

    @media @bpXSmall {
        background: #fff;
    }

    &:last-child {
        border: none;
        margin-bottom: 0;
    }
}

/**/

.CardinStock--row{
    margin-bottom: 0.45em;

      @media @bpMedium {
       margin-bottom: @spaceSmall;
    }
}

.CardStockItem--row{
    font-size: @textSizeMedium;
}

.Cardheading--row{
     font-size: @textSizeMedium;
    line-height: 1.4em;
    margin-bottom: 0.3em;
    margin-top: 0.5em;

      @media @bpMedium {
        font-size: @textSizeXLarge;
        line-height: 24px;
    }
}

.Cardfirm--row{
    display: inline;

    @media @bpMedium {

    }
}

/* BMW Selection */
.Card-bmwBorder {
    border: 3px solid @colorLightGreen;
}

.Card-bmwText {
    position:absolute;
    right:auto;
    left:auto;
    top:0;
    padding:3px;
    background: rgba(0, 0, 0, 0.5);
    width:100%;
    text-align: center;

}
.Card-bmwText span{
    font-size: @textSizeSmall;
    color: #fff;
}

.Card-bmwText--row {
    padding:2px 2px 2px 6px;
    background: rgba(0, 0, 0, 0.5);
    width:100%;
    text-align: left;

    @media @bpMedium {
        text-align:  middle;
        position:absolute;
        left:0;
        top:0;

    }

}

.Card-bmwText--row span{
    font-size: @textSizeXXSmall;
    color: #fff;
}

.Card-button {
    align-items: flex-end;
	display: flex;
    padding-right: 0.5em;
    font-size: @textSizeSmall;

    @media @bpXSmall {
        padding-right: 1.3em;
    }

}




.Card-button-list {

}
