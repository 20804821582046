
@import "../../common/variables.less";

.alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgba(114, 28, 36, 0.2);

    align-items: center;
    font-family: Arial, sans-serif;
}

.alert-error svg {
    margin-right: 10px;
    width: 24px;
    height: 24px;
}

.alert-error p {
    margin: 0;
    font-size: 16px;
    line-height: 1.4;
}