.u-transitionAsm{
    -webkit-transition: ease-out 0.2s;
    -moz-transition:  ease-out 0.2s;
    -o-transition:  ease-out 0.2s;
    transition:  ease-out 0.2s;
}


.u-transitionAmd {
    -webkit-transition: ease-out 0.5s;
    -moz-transition:  ease-out 0.5s;
    -o-transition:  ease-out 0.5s;
    transition:  ease-out 0.5s;
}

.u-transitionMHsm{
    -webkit-transition: max-height ease-out 0.2s;
    -moz-transition: max-height ease-out 0.2s;
    -o-transition: max-height ease-out 0.2s;
    transition: max-height ease-out 0.2s;
}

.u-transitionMHmd{
    -webkit-transition: max-height ease-out 0.5s;
    -moz-transition: max-height ease-out 0.5s;
    -o-transition: max-height ease-out 0.5s;
    transition: max-height ease-out 0.5s;
}


.u-transitionPaddingmd{
    -webkit-transition: padding ease-out 0.5s;
    -moz-transition: padding ease-out 0.5s;
    -o-transition: padding ease-out 0.5s;
    transition: padding ease-out 0.5s;
}