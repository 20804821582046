/*#
Styling for ZebraList.

Examples:
<div style="width:350px;">
   <div class="ZebraList">
    <div class="Zebra-HeadingBox">
        <h3 class="Zebra-Heading u-colorTextGrey">Familje raketen</h3>
    </div>
    <ul class="List List--vertical List--gutterHlg List--gutterVsm u-marginAz">
        <li class="List-item u-paddingTxsm"><a href="#"><span class="u-textWeightLight">1. </span>Toyota Prius <span class="u-textWeightLight">1.8 EXECUTIVE</span></a></li>
        <li class="List-item"><a href="#"><span class="u-textWeightLight">2. </span>Volvo V60 <span class="u-textWeightLight">D4 Momentum</span></a></li>
        <li class="List-item"><a href="#"><span class="u-textWeightLight">3. </span>Saab 9-3, <span class="u-textWeightLight">1.8t Kombi Linear</span></a></li>
        <li class="List-item"><a href="#"><span class="u-textWeightLight">4. </span>BMW 916, <span class="u-textWeightLight">Adv. edition</span></a></li>
        <li class="List-item"><a href="#"><span class="u-textWeightLight">5. </span>Toyota Prius <span class="u-textWeightLight">1.8 EXECUTIVE</span></a></li>
        <li class="List-item"><a href="#"><span class="u-textWeightLight">6. </span>Volvo V60 <span class="u-textWeightLight">D4 Momentum</span></a></li>
        <li class="List-item"><a href="#"><span class="u-textWeightLight">7. </span>Saab 9-3, <span class="u-textWeightLight">1.8t Kombi Linear</span></a></li>
        <li class="List-item"><a href="#"><span class="u-textWeightLight">8. </span>BMW 916, <span class="u-textWeightLight">Adv. edition</span></a></li>
        <li class="List-item"><a href="#"><span class="u-textWeightLight">9. </span>Toyota Prius <span class="u-textWeightLight">1.8 EXECUTIVE</span></a></li>
        <li class="List-item u-paddingBxsm"><a href="#"><span class="u-textWeightLight">10. </span>Volvo V60 <span class="u-textWeightLight">D4 Momentum</span></a></li>
    </ul>
</div>
</div>

The long list
<div style="width:900px;">
   <div class="Grid u-marginTz">
        <div class="Grid-cell u-sm-size1of3">
            <div class="ZebraList u-marginBsmall ZebraList--horizontal ">
                <ul class="List List--vertical List--gutterHlg List--gutterVsm u-marginAz u-colorTextGrey">
                    <li class="List-item u-paddingTxsm"><a href="#"><span class="u-textWeightLight">1. </span>Toyota Prius <span class="u-textWeightLight">1.8 EXECUTIVE</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">2. </span>Volvo V60 <span class="u-textWeightLight">D4 Momentum</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">3. </span>Saab 9-3, <span class="u-textWeightLight">1.8t Kombi Linear</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">4. </span>BMW 916, <span class="u-textWeightLight">Adv. edition</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">5. </span>Toyota Prius <span class="u-textWeightLight">1.8 EXECUTIVE</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">6. </span>Volvo V60 <span class="u-textWeightLight">D4 Momentum</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">7. </span>Saab 9-3, <span class="u-textWeightLight">1.8t Kombi Linear</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">8. </span>BMW 916, <span class="u-textWeightLight">Adv. edition</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">9. </span>Toyota Prius <span class="u-textWeightLight">1.8 EXECUTIVE</span></a></li>
                    <li class="List-item u-paddingBxsm"><a href="#"><span class="u-textWeightLight">10. </span>Volvo V60 <span class="u-textWeightLight">D4 Momentum</span></a></li>
                </ul>
            </div>
        </div>

        <div class="Grid-cell u-sm-size1of3 listResponsive-hide u-transitionAsm js-showMore">
            <div class="ZebraList u-marginBsmall ZebraList--horizontal">
                <ul class="List List--vertical List--gutterHlg List--gutterVsm u-marginAz u-colorTextGrey">
                    <li class="List-item u-paddingTxsm"><a href="#"><span class="u-textWeightLight">11. </span>Toyota Prius <span class="u-textWeightLight">1.8 EXECUTIVE</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">12. </span>Volvo V60 <span class="u-textWeightLight">D4 Momentum</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">13. </span>Saab 9-3, <span class="u-textWeightLight">1.8t Kombi Linear</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">14. </span>BMW 916, <span class="u-textWeightLight">Adv. edition</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">15. </span>Toyota Prius <span class="u-textWeightLight">1.8 EXECUTIVE</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">16. </span>Volvo V60 <span class="u-textWeightLight">D4 Momentum</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">17. </span>Saab 9-3, <span class="u-textWeightLight">1.8t Kombi Linear</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">18. </span>BMW 916, <span class="u-textWeightLight">Adv. edition</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">19. </span>Toyota Prius <span class="u-textWeightLight">1.8 EXECUTIVE</span></a></li>
                    <li class="List-item u-paddingBxsm"><a href="#"><span class="u-textWeightLight">20. </span>Volvo V60 <span class="u-textWeightLight">D4 Momentum</span></a></li>
                </ul>
            </div>
        </div>

        <div class="Grid-cell u-sm-size1of3 listResponsive-hide u-transitionAsm js-showMore">
            <div class="ZebraList u-marginBsmall ZebraList--horizontal">
                <ul class="List List--vertical List--gutterHlg List--gutterVsm u-marginAz u-colorTextGrey">
                    <li class="List-item u-paddingTxsm"><a href="#"><span class="u-textWeightLight">21. </span>Toyota Prius <span class="u-textWeightLight">1.8 EXECUTIVE</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">22. </span>Volvo V60 <span class="u-textWeightLight">D4 Momentum</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">23. </span>Saab 9-3, <span class="u-textWeightLight">1.8t Kombi Linear</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">24. </span>BMW 916, <span class="u-textWeightLight">Adv. edition</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">25. </span>Toyota Prius <span class="u-textWeightLight">1.8 EXECUTIVE</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">26. </span>Volvo V60 <span class="u-textWeightLight">D4 Momentum</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">27. </span>Saab 9-3, <span class="u-textWeightLight">1.8t Kombi Linear</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">28. </span>BMW 916, <span class="u-textWeightLight">Adv. edition</span></a></li>
                    <li class="List-item"><a href="#"><span class="u-textWeightLight">29. </span>Toyota Prius <span class="u-textWeightLight">1.8 EXECUTIVE</span></a></li>
                    <li class="List-item u-paddingBxsm"><a href="#"><span class="u-textWeightLight">30. </span>Volvo V60 <span class="u-textWeightLight">D4 Momentum</span></a></li>
                </ul>
            </div>
        </div>
    </div>
<div class="u-sm-hidden u-textCenter">
    <span class="listTextToggler" data-module="toggler" data-toggler="contentElement: '.js-showMore', cssClass: 'is-active', addClassToSelf: true"></span>
</div>
</div>

*/
@import "../../common/variables.less";

.ZebraList {
    margin-bottom: @spaceMedium;

    @media @bpXSmall {
        margin-bottom: 0;
    }
}

.Zebra-HeadingBox {
    background: @colorGrey1;
    padding: 0.2em 1.25em;
    @media @bpSmall {
        height:5em;
    }

    @media @specialWidthToplist {
        height:2.7em;
    }

}

.Zebra-Heading {
    margin-bottom: 0;
    font-size: 25/16em;
    font-weight: 700;
}

.ZebraList li {
    border-left: 1px solid @colorGrey1;
    border-right: 1px solid @colorGrey1;
    font-weight: 600;
    font-size: 15/16em;
    padding: 0.2em 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor:pointer;

    a {
        color: @colorTextGrey;
        display: block;

        &:hover {
            text-decoration: none;
        }
    }

    &:hover {
        background: @colorGrey2;
    }
}

.ZebraList--horizontal {
    margin-bottom: 0;

    @media @bpMedium {
        border-top: 1px solid @colorGrey1;
    }

    li {
        border: none;
    }
}

.ZebraList li:nth-child(even) {
    background: #f8f8f8;

    &:hover {
        background: @colorGrey2;
    }
}

.ZebraList {
    .ListResponsive-toggler {
        padding: 0 1em;

        &.is-active {
            padding: 0.2em 1em;
        }
         @media @bpMedium {
            padding: 0.2em 1em;
        }
    }
    li {
        div.openinghours-day {
            display: inline-block;
            font-weight: normal;
            width: 3em;
            .u-textSmall
        }
        div.openinghours-time {
            display: inline-block;
            font-weight: normal;
            width: 3em;
            .u-textSmall
        }
    }

    .List-textToggler {
        display: block;
        background: #f8f8f8;
        padding: 0.2em 1em;
        position: relative;
        top: -5px;
         &.is-active{
             background: #fff;
         }

    }
}
