
@import "../../common/variables.less";

.login-box{
	.u-paddingAmd;
	width: 260px !important;
	background-color: #e9e7ea;
	font-size: @textSizeSmall;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	-webkit-box-shadow: 0 6px 4px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0 6px 4px rgba(0, 0, 0, 0.5);
	box-shadow: 0 6px 4px rgba(0, 0, 0, 0.5);
	position: absolute;
	right: 27px;
    top: 6px;
    display: none;
	.login-forgot-psw-text{
		margin-left: -13px;
		width: 260px;
		background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(204, 204, 204, 1) 0%, rgba(233, 231, 234, 1) 30%, rgba(233, 231, 234, 1) 100%) repeat scroll 0 0;
		padding: 1.5em 1.25em 0 !important;
	}
	#forgot-password{
		display: none;
	}
	.Form-field{
		font-size: @textSizeLarge !important;
	}
}