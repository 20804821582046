/*#

Listing that shows checkmarks for true or false

Examples:
 
<div>
  <div class="TraderInfo u-md-size1of2 u-marginBxlg">
                <h5 class="traderInfo-heading">Övriga tjänsteutbud</h5>
                <ul class="List List--horizontal List--bordered  u-sm-size1of1 u-paddingHsm traderInfo-list List--allbordered">
                    <li class="List-item u-size1of2 u-md-size1of1">
                        <span class="Icon Icon--checkmark Icon--traderInfo IconCheckmark--green u-marginRxsm u-textWeightBold"></span><span class="u-marginAz u-textWeightLight">Finansiering</span>
                    </li>
                    <li class="List-item u-size1of2 u-md-size1of1">
                        <span class="u-marginAz u-textWeightLight u-colorTextLight">Försäkring</span>
                    </li>
                    <li class="List-item u-size1of2 u-md-size1of1">
                        <span class="Icon Icon--checkmark Icon--traderInfo IconCheckmark--green u-marginRxsm u-textWeightBold"></span><span class="u-marginAz u-textWeightLight">Däckhotell</span>
                    </li>
                    <li class="List-item u-size1of2 u-md-size1of1">
                        <span class="Icon Icon--checkmark Icon--traderInfo IconCheckmark--green u-marginRxsm u-textWeightBold"></span><span class="u-marginAz u-textWeightLight">Biluthyrning</span>
                    </li>
                </ul>
            </div>
</div>
*/

@import "../../common/variables.less";

.TraderInfo {
    @media @bpMedium {
        border-left:1px solid @borderColor;
    }
}

.traderInfo-heading {
      margin-left:@spaceMedium;
    @media @bpMedium {
        margin-left:@spaceLarge;
    }
}

.traderInfo-list {
    &.List--allbordered {
        li.List-item {
            position: relative;

            @media @bpMedium {
                padding:@spaceSmall @spaceMedium; &:last-child {
                    padding-bottom: @spaceSmall;
                }
            }
        }
    }

    span {
            padding-left: @spaceMedium;
        }

    .Icon--traderInfo {
        position: absolute;
        top: 7px;
        left: 0px;
        padding:0;
        @media @bpMedium {
            top: 14px; 
            left: 16px;
        }
    }
}