@import (reference) "../common/variables.less";

html {
    box-sizing: border-box;
    font-size: 100%;
    font-family: @fontFamilyPrimary;
    font-size: @bodyFontSizeBase;
    font-weight: @baseFontWeight;
    line-height: 1.8;

    // increase font sizes for wider screen widths
    @media only screen and (min-width: unit((@widthSmall * @baseFontSize), px)), print {
        font-size: @bodyFontSizeSm;
        line-height: 1.5;
    }

    @media only screen and (min-width: unit((@widthMedium * @baseFontSize), px)), print {
        font-size: @bodyFontSizeMd;
    }

    @media only screen and (min-width: unit((@widthLarge * @baseFontSize), px)), print {
        font-size: @bodyFontSizeLg;
    }

    @media only screen and (min-width: unit((@widthXLarge * @baseFontSize), px)), print {
        font-size: @bodyFontSizeXLg;
    }
}

*, *::before, *::after {
  box-sizing: inherit; // https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
}

body {
    background-color: #fff;
    color: @colorText;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

/**
 * Typography
 */

// NOTE: replace this with custom Web Fonts

@import "../fonts/museosans/_museosans";
@import "../fonts/opensanscond/_opensanscond";

// NOTE: heading stylings can be found in Heading.less

p {
    // margin-top: 0;
    margin-bottom: 1em;
    font-size: @textSizeLarge;

    @media @bpMedium {
        font-size: @textSizeSmall;
    }
}

ol, ul, dl {
    // margin-top: 0;
    margin-bottom: 1em;
}

// reset margin-bottom on nested combinations of "ol ul"
ol, ul {
    & & {
        margin-bottom: 0;
    }
}

/**
 * Links
 */

a {
    color: @colorLink;
    text-decoration: none;
    font-size: @textSizeLarge;
    font-weight: 500;

    @media @bpMedium {
        font-size: @textSizeSmall;
    }
}

a:hover {
    color: @colorLinkHover;
    text-decoration: underline;
}

/**
 * Elements
 */
*:focus {
     outline: none;
 }

img {
    max-width: 100%;
    vertical-align: middle;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid @borderColor;
    margin: @spaceLarge 0;
    padding: 0;
}

figure {
    margin: 0;
}
select, option {
    color: @colorTextGrey!important;
    border-radius:1px;
    &:focus {
        outline: none;
    }
}
/**
 * Tables
 */

table {
    width: 100%;
    max-width: 100%;
    margin: 0 0 1em 0;

    th, td {
        padding: .5em;
        vertical-align: top;
        text-align: left;
    }

    th {
        background: @colorGrey3;
        font-weight: normal;
    }

    td {
        border-bottom: 1px solid @colorGrey3;
    }
}



/**
 * Print styles.
 * Inlined to avoid required HTTP connection.
 */

@media print {
    @page {
        margin: 0.5cm;
    }

    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    // remove Episerver bar from print
    #epi-quickNavigator {
        display: none !important;
    }
}
