@import "../../common/variables.less";

.user-registration-form {
	

	.mdl-checkbox.is-invalid{
		.mdl-checkbox__box-outline{
			border-color: #d50000;
		}

		.mdl-checkbox__label {
			color: #d50000;
		}
	}

	.mdl-menu__outline {
	    width: 100% !important;
	}
	.reg-circle {
		border-radius: 50%;
		width: 40px;
		height: 40px;
		background-color: #1F94CE;
		color: white;
		display:table-cell;
	    text-align:center;
	    vertical-align:middle;
	}

	.reg-label {
		position: relative;
		top: 0.5em;
		left: 1.3em;
	}

	.reg_form_part {
		border-left: 1px  solid #D3D3D3;
		position: relative;
		left: 1.2em;
		.u-marginVsm;
	}

	.is-active {
		display: flex !important;
		display: -ms-flexbox !important;
		height: inherit !important;
		overflow: inherit !important;
	}

	.js-showFilter-part-1, .js-showFilter-part-2, .js-showFilter-part-3 {
		height: 1em;
		overflow: hidden;
		.u-paddingTmd;
	}

	.js-showFilter-part-4 {
		height: 0em;
		overflow: hidden;
	}

	button {
		font-size: 0.75em;
		span {
			display: block;
		    pointer-events: none;
		}
	}

	a {
		color: #737373;
		text-decoration: none;
		margin-left: 2.8em;
		font-weight: 300;
		&:hover{
			text-decoration: underline;
		}
	}

	.green_circle {
		background-color: #29B853;

		i {
			font-size: 19px !important;
			position: relative;
			top: 4px;
		}
	}

	#selectbox--focus{
		z-index: -110;
	}

	#country{
		cursor: pointer !important;
	}

	#selectbox--focus{
		cursor: pointer !important;
	}

	.mdl-menu__container {
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	}

	.custom_width_120px {
		width: 120px;
	}



	#sms_validation_part_2 {
		display: none;
	}

	#registration_done {
		position: relative;
		left: 1.2em;
		top: 1em;
	}

	#registration_done_h {
	    top: -0.5em;
	    position: relative;
	}




}
