@import "../../common/variables.less";

.auc-box{
	&:extend(.mdl-shadow--2dp);
	&:extend(.u-borderRoundBsm);
	margin-bottom: 2em;

	@media @max-bpMedium {
		box-shadow: none;
		margin-bottom: 0;
		border-bottom: 1px solid #fff;
	}

	.header {
		&:extend(.u-colorBgGrey);
		&:extend(.u-colorText);

		font-weight: bold;
		padding: 0.75em @spaceMedium;
		cursor: pointer;

		i {
			font-size: 6px;
			color: @colorTextLight;
			position: relative;
			top: 10px;
		}
	}

	.body {
		padding: @spaceXSmall @spaceMedium 2.5em @spaceMedium;

		@media @bpMedium {
			padding-bottom: @spaceMedium;
		}

		.Button {
			width: 132px;
			font-size: @textSizeXSmall;
			padding: 0.825em 0 0.725em 0;
			margin-top: 1em;
			border: none;
			margin-right: @spaceSmall;
			font-weight: 500;

			@media @bpSmall {
				min-width: 180px;
				width: auto;
				padding-left: 1.3em;
				padding-right: 1.3em;
			}

			&:last-child{
				maring-right: 0;
			}
		}
	}
}