@import "../../common/base.less";
.marketplace {
	h1 {
		color: #2E2E2E;
		font-size: 1.4em;
		margin-top: 2em;
		font-weight: 300;
		line-height: 1.3em;
	}

	.presentation__text {
		font-size: 1.1em;
		font-weight: 100;
		line-height: 1.3em;
		font-style: italic;
		color: #4A4A4A;
		position: relative;
		white-space: nowrap;
		margin-bottom: 5.4em;
	}

	a {
		position: relative;
		top: 0.85em;
		font-size: 0.99em !important;
		font-style: normal !important;
		font-weight: 300 !important;
	}

	#pre_text {
		display: block;
		display: inline-block;
	}

	#bwa-beetle {
		float: right;
		position: relative;
		top: -2.5em;
	}

	@media (max-width: 1070px) {
		padding-left: 3em;
		padding-right: 3em;
		#bwa-beetle {
			top: -0.1em;
			width: 19em;
		}
	}
	@media (max-width: 970px) {
		#bwa-beetle {
			top: 0.9em;
			width: 17em;
		}
	}
	@media (max-width: 920px) {
		h1 {
			font-size: 1.4em;
		}

		.presentation__text {
			font-size: 1.1em;
			line-height: 1.3em;
		}
	}
	@media (max-width: 920px) {
		h1 {
			font-size: 1.4em;
		}

		.presentation__text {
			font-size: 1.1em;
			line-height: 1.3em;
		}
	}

	#presentation__text__desktop {
		@media (min-width: 800px) {
			display: block !important;
		}
		display: none !important;
	}

	#presentation__text__mobile {
		@media (min-width: 800px) {
			display: none !important;
		}
		display: block !important;
		white-space: normal;
	}

	@media (max-width: 800px) {
		#bwa-beetle {
			top: 1.9em;
			width: 17em;
		}
	}

	@media (max-width: 740px) {
		#bwa-beetle {
			position: absolute;
			top: 5em;
			width: 14em;
			right: -5em;
		}
	}

	@media (max-width: 662px) {
		#bwa-beetle {
			top: 5.5em;
			width: 14em;
			right: -5em;
		}
		#presentation__text__mobile {
			width: 30em;
		}

		#pre_text {
			display: none;
		}
	}

	@media (max-width: 580px) {
		#bwa-beetle {
			top: 5.5em;
			width: 14em;
			right: -5em;
		}
	}

	@media (max-width: 540px) {
		#bwa-beetle {
			top: 5.5em;
			width: 14em;
			right: -5em;
		}
		#presentation__text__mobile {
			width: 100%;
		}
	}

	@media (max-width: 480px) {
		padding-left: 1em;
		padding-right: 1em;
		#bwa-beetle {
			top: 6.5em;
			width: 13em;
			right: -3.5em;

		}
	}

	@media (max-width: 430px) {
		#bwa-beetle {
			top: 7em;
			width: 14em;
			right: -2em;
		}
	}

	@media (max-width: 382px) {
		#bwa-beetle {
			top: 7.5em;
		}
	}


	@media (max-width: 350px) {
		h1 {
			color: #2E2E2E;
			font-size: 1.4em;
			font-weight: 300;
		}
		#bwa-beetle {
			top: 8.5em;
			width: 14em;
			right: -2.3em;
		}
	}





}

.our_auctions {
	padding-bottom: 4.3em;
	background-color: #F4F4F4;
	h1 {
		color: #2E2E2E;
		font-size: 1.4em;
		font-weight: 300;
	}

	a {
		font-size: 0.9em !important;
	}

	@media (max-width: 1070px) {
		h1 {
			padding-top: 1.5em;
		}
	}

	@media (max-width: 1040px) {
		padding-left: 3em;
		padding-right: 3em;
	}

	@media (max-width: 984px) {
		.btn_block div {
			width: 50% !important;
			margin-top: 1.5em;
		}
		.btn_block_wrapper{
			margin-top: 0em !important;
		}
	}

	@media (max-width: 918px) {
		h1 {
			padding-top: 2em;
		}
	}

	@media (max-width: 480px) {
		padding-left: 1em;
		padding-right: 1em;
		.left-b {
			padding-left: 20px !important;
			padding-right: 10px !important;
		}
		.right-b {
			padding-right: 20px !important;
			padding-left: 10px !important;
		}
	}

	@media (max-width: 430px) {
		h1 {
			padding-top: 3.5em;
		}
	}

	@media (max-width: 424px) {
		a {
			font-size: 0.85em !important;
		}

		.Button {
			padding: 17px 16px 15px 16px;
		}

	}


}
.highlight {
	padding-top: 2em;
	padding-bottom: 4em;
	/*background-color: #F4F4F4;*/
	h1 {
		color: #2E2E2E;
		font-size: 1.4em;
		font-weight: 300;
		line-height: 1.3em;
	}
}

.most_clicked {
	padding-top: 2em;
	padding-bottom: 4em;
	/*background-color: #F4F4F4;*/
	h1 {
		color: #2E2E2E;
		font-size: 1.4em;
		font-weight: 300;
		line-height: 1.3em;
	}
	#most_clicked_hidden {
		display: none;
	}

	.item_link {
		text-decoration: none;
	}

	.item:hover {
		.title_link {
			text-decoration: underline !important;
			color: #34B658;
		}
	}

	.mdl-card--expand{
		display: block;
		height: 100%;
	}

	/* Safari only */
	.mdl-card--expand:not(:root:root) {
		height: initial;
	}

}

.hero {
	background:url('https://bilweb-auctions-images.s3.amazonaws.com/main_hero_image.jpg') center bottom no-repeat;
	background-size: cover;
	padding-top: 4.3em;
	.hero--btn{
		margin-top: 1.22em;
		margin-bottom: 1.9em;
		padding-right: 84px;
		padding-left: 84px;
	}



	button {
		bottom: -2em;
		color: #1F94CE !important;
	}

	@media (max-width: 740px) {
		#hero--read-more {
			/*display: none;*/
		}

	}


	@media (max-width: 380px) {
		padding-top: 2.3em;
		#hero_big_text {
			top:0.6em !important;
			font-size: 1.6em !important;
		}
		#hero_small_text {
			top:-0.6em !important;
			font-size: 0.88em !important;
		}
	}


}

.financeInfo {
	font-size: 1.15em !important;
	@media (max-width: 768px) {
		font-size: 0.85em !important;
	}
}

.owl-carousel .item {
	padding: 4px;
}

.demo-card-square.mdl-card {
	width: 100%;
	height: 100%;
}
.most_clicked {
	.most_clicked_image {
		padding: 0px;
	}

	.most_clicked_text {
		font-size: 1em;
		color: #4A4A4A;
		font-weight: normal;

	}
	.breadtext {
		font-size: 0.75em;
		font-weight: 300;
		color: #4A4A4A;
		height: 3.9em;
		overflow: hidden;
	}

	.mdl-card__price {
		height: 4em;
		min-height: 4em;
	}

	.block {
		display: inline;
	}

	.mdl-card__supporting-text {
		width: 100%;
		line-height: 1.3em;
	}


	@media (max-width: 440px) {
		.most_clicked_text {
			font-size: 1em !important;
		}

		.breadtext {
			font-size: 1em !important;
		}
	}

	.owl-next {
		color: #BFBFBF !important;
		padding: 0px;
		border-radius: 0px;
		background-color: transparent !important;
		top: 10em;
		right: -4em;
		.material-icons {
			font-size: 3em;
		}
	}

	.owl-prev {
		color: #BFBFBF !important;
		padding: 0px;
		border-radius: 0px;
		background-color: transparent !important;
		left: -4em;
		top: 10em;
		.material-icons {
			font-size: 3em;
			padding: 0px;
		}
	}

	.owl-next:before {
		content: "" !important;
	}

	.owl-next:after {
		content: "" !important;
	}


	.owl-prev:before {
		content: "" !important;
	}
}


/* styles for '...' */
.news_preview {
	/* hide text if it more than N lines  */
	overflow: hidden;
	/* for set '...' in absolute position */
	position: relative;
	/* use this value to count block height */
	line-height: 1.24em;
	/* max-height = line-height (1.2) * lines max number (3) */
	max-height: 3.6em;
	/* fix problem when last visible word doesn't adjoin right side  */
	text-align: justify;
	/* place for '...' */
	margin-right: -1em;
	padding-right: 1em;
}
/* create the ... */
.news_preview:before {
	/* points in the end */
	content: '...';
	/* absolute position */
	position: absolute;
	/* set position to right bottom corner of block */
	right: 0;
	bottom: 0;
}
/* hide ... if we have text, which is less than or equal to max lines */
.news_preview:after {
	/* points in the end */
	content: '';
	/* absolute position */
	position: absolute;
	/* set position to right bottom corner of text */
	right: 0;
	/* set width and height */
	width: 1em;
	height: 1em;
	margin-top: 0.2em;
	/* bg color = bg color under block */
	background: white;
}

.news_block {
	h3 {
		color: #2E2E2E;
		font-size: 1.4em;
		font-weight: 300;
		padding-bottom: 0.5em;
		line-height: 1.3em;
	}
}

.carousel-most-clicked {

	@media (max-width: 1116px) {
		padding-left: 3em;
		padding-right: 3em;
	}
}

.mobile_news_header a {
	font-weight: 300 !important;
	font-size: 1em !important;
}

.is_404{
	background-color: #ffd9c8;
	width: 100%;
	padding: 2em;
	margin: 0px;
	p{
		margin: 0em;
	}
	h5{
		margin-bottom: 0.2em;
	}
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%;
	/* 16:9 */
	padding-top: 25px;
	height: 0;
}

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.input_search {
	border-radius: 50px;
	position: relative;
	top: -4px;
	border-width: 0px;
	padding: 17px 20px 15px 20px;
	font-weight: 300;
	color: dimgray;
	width: 240px;
}

.input_search::placeholder {
  color: darkgray;
  opacity: 1; /* Firefox */
}

.input_search::-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: darkgray;
 text-align: center;
}

.input_search::-ms-input-placeholder { /* Microsoft Edge */
 color: darkgray;
 text-align: center;
}


.btn-search {
	padding-right: 25px !important;
	padding-left: 25px !important;
}

.accordion {
	border-bottom: #dedede 1px solid;
}

.accordion h2 {
	margin: 14px;
}

.accordion p {
	margin: 14px;
}