@import "../../common/variables.less";

#news-list-wrapper {
	.u-paddingAmd;
	@media (max-width: 420px) {
        .u-paddingAsm;
    }
}

.news_wrapper h2 {
	line-height: 1;
	@media (max-width: 544px) {
        font-size: 1.4em;
    }
    @media (max-width: 420px) {
        font-size: 1.2em;
    }
}
.news_wrapper h1 {
	line-height: 1;
	@media (max-width: 544px) {
        font-size: 1.4em;
    }
    @media (max-width: 420px) {
        font-size: 1.2em;
    }
}

.news-list h2 {
	line-height: 1;
	color: @colorLightGreen;
	@media (max-width: 544px) {
        font-size: 1.4em;
    }
    @media (max-width: 420px) {
        font-size: 1.2em;
    }
}

.news_wrapper .subtitle{
	font-size: @textSizeSmall;
	@media (min-width: 543px) {
        .u-paddingLmd;
    }
    @media (max-width: 543px) {
        .u-paddingTlg;
    }
}

.news_wrapper .featured-author_info{
	color: @colorGrey3;
	line-height: 1;
}

.news_wrapper .author_info{
	color: @colorGrey3;
	line-height: 1;
}


.news_wrapper {
	.u-marginBmd;
	border: solid 1px @borderColor;
	padding: @spaceMedium;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
}

.news_wrapper .featured-pubtxt {
	.u-pullRight;
	@media (max-width: 388px) {
        .u-pullLeft;
        width:100%;
    }
}

.pagination .prev_url_link {
	display: inline;
	@media (max-width: 520px) {
        display:none;
    }
}
.pagination .prev_url_link_small {
	display: none;
	@media (max-width: 520px) {
        display:inline;
    }
}

.pagination .next_url_link {
	display: inline;
	@media (max-width: 520px) {
        display:none;
    }
}


.pagination .next_url_link_small {
	display: none;
	@media (max-width: 520px) {
        display:inline;
    }
}
