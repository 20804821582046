/*#

PriceBox

Examples:

<div style="width:300px;">
  <div class="PriceBox u-marginBlg">
    <p class="PriceBox-price u-textCenter">109 900 kr</p>
    <span class="PriceBox-monthly u-textCenter">från 1090 kr/mån</span>
   </div>
</div>
*/
#floatElement, #floatMe {
    position:absolute;
    transition: 200ms;
    -webkit-transition: 200ms;
    -o-transition: 200ms;
}
@import "../../common/variables.less";
.PriceBox
{
}
.PriceBox-price {
    border: 1px solid @colorLightGreen;
    font-size: 24px;
    color: #353535;
    line-height: 29px;
    margin:0 0 2px 0;
    padding: 0.5em 0;
}
.PriceBox-monthly {
    font-size: 12px;
    color: #fff;
    line-height: 14px;
    background: @colorLightGreen;
    display:block;
    padding: 0.5em 0;
    text-decoration: underline;
}

