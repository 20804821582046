/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 2016-mar-14, 11:20:02
    Author     : pontus
*/

.success_message, .fail_message {
    display: none;
}

.LeadsModal{
    background-color: #f5f5f5 !important;
    padding: 2em !important;
    max-width: 730px !important;
    color: #4a4a4a;
    @media (max-width: 767px) {
         max-width: 400px !important;
    }
    @media (max-width: 420px) {
         padding: 1em !important;
    }
    h3{
        margin-bottom: 0.1em;
        color: #4a4a4a;
        line-height: 1;
    }
    hr{
        margin-top: 0em !important;
        margin-bottom: 1em;
    }
    label{
        color: #a4a4a4;
    }
    input{
        background: white none repeat scroll 0 0;
        border: 0 none;
        border-radius: 2px;
        box-shadow: 0 1px 0 1px #e3e3e3 inset;
        color: black;
        font-size: 16px;
        font-weight: lighter;
        line-height: 19px;
        padding: 6px;
        
    }
    textarea{
        background: white none repeat scroll 0 0;
        border: 0 none;
        border-radius: 2px;
        box-shadow: 0 1px 0 1px #e3e3e3 inset;
        color: black;
        font-size: 16px;
        font-weight: lighter;
        line-height: 19px;
        padding: 6px;
        resize: none;
        display: none;
    }
    #regnr-field{
            font-size: 13px;
            line-height: 15px;
            padding: 5px;
            width: 6em;
            text-align: center;
            padding-left: 0em;
            padding-right: 0em;
            font-weight: normal;
            text-indent: 0;
        }
    
    .radioOption-indicator {
        &:after {
            background-color: white;
            display: inline-block;
            width: 22px;
            height: 22px;
            border-radius: 100%;
            border: 1px solid @colorTextLight;
            content: "\2611";
            color: #fff;
            padding: 5px 4px;
            font-size: 11px;
            font-weight: 700;
            position: relative;
            top: 0.1em;
        }

        &.is-active {
            &:after {
                background: @colorLightGreen;
                border: 1px solid @colorLightGreen;
            }
        }
    }
    .CheckboxOption-indicator {
        &:after {
            background-color: white;
            display: inline-block;
            width: 22px;
            height: 22px;
            border: 1px solid @colorTextLight;
            content: "\2611";
            color: #fff;
            padding: 5px 4px;
            font-size: 11px;
            font-weight: 700;
            position: relative;
            top: 0.1em;
        }

        &.is-active {
            &:after {
                background: @colorLightGreen;
                border: 1px solid @colorLightGreen;
            }
        }
    }
    #is-infoSimObj{
        position: relative;
        top: 0.1em;
    }
    #add-msg{
        padding: 0.95em;
        width: 100%;
        .u-textXSmall;
        .u-textWeightLight;
        .u-marginRmd;
        @media (max-width: 768px) {
            .u-pullLeft;
            .u-marginAz;
            .u-marginTmd;
            .u-marginBmd;
        }
        @media (max-width: 420px) {
            .u-pullRight;
            
        }
    }
    #send-btn{
        width: 100%;
        @media (max-width: 420px) {
            .u-pullLeft;
        }
    }
    #is-infoSimObj-label{
        line-height: 1;
    }
    .Modal-close {
        position: absolute;
        top: 0em;
        right: 0em;
        font-size: 1.4em;
        text-decoration: none;
        padding: 0.1em 0.3em 0.1em;
        background: #4a4a4a;
        line-height: 1em;
        color: #f5f5f5;
        border-radius: 0%;
        font-weight: 100;
    }
    .img-box{
        .u-pullLeft; 
        .u-paddingRmd;
        .u-marginBmd;
        @media (max-width: 767px) {
            float: none;
            .u-paddingAz;
            .u-marginAz;
        }
    }
    .form-box{
        .u-pullRight; 
        .u-paddingLmd; 
        .u-marginBmd;
        @media (max-width: 767px) {
            float: none;
            .u-paddingAz;
            .u-marginAz;
            .u-marginTmd;
            .u-marginBmd;
        }
    }
    .radio-phone{
        @media (min-width: 768px) {
            .u-marginTxsm;
        }
    }
    .marginBmd{
        @media (max-width: 767px) {
            .u-marginBmd;
        }
    }
    .button-and-info-box{
        .u-marginAz;
        .u-paddingAz;
        .u-marginTxlg;
        @media (max-width: 768px) {
            .u-marginAz;
        }
    }
    .button-box{
        @media (max-width: 768px) {
            .u-marginTmd;
        }
    }
    form{
        .toggle-hide{
            @media (max-width: 420px) {
                .u-hidden;
            }
        }
        input, textarea{
            @media (max-width: 420px) {
                .u-marginTsm;
                .u-textMedium;
            }
        }
    }
    .contact_type_email, .contact_type_phone, .tradein, .avalible, .finance, .warranty, .want_newsletter {
        cursor: pointer;
    }
    .dt-email-error {
        display: none;
        font-size: 14px;
        left: 481px;
        position: absolute;
        top: 70px;
        @media (max-width: 920px) {
                top: 51px;
        }
        @media (max-width: 767px) {
                display: none !important;
        }
    }
    .dt-email-error img {
        left: -51px;
        position: relative;
        top: 81px;
    }
    .dt-email-error-multi {
        left: 510px;
    }
    #from{
        @media (min-width: 767px) {
                background-color: white !important;
        }
    }
    .multi-contact-me{
        @media (max-width: 767px) {
            .u-marginTmd;
        }
    }
}