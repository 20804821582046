/*#


Examples:

<div style="clear:both; overflow:hidden;">
<nav class="PageNav PageNav--horizontal PageNav--large Header-primaryNav">
    <ul class="PageNav-list">
        <li class="PageNav-item">
            <a href="#" class="PageNav-itemLink is-active u-transitionAsm">Sök eller köp bil</a>
        </li>
        <li class="PageNav-item">
            <a href="#" class="PageNav-itemLink u-transitionAsm">Sälj bil</a>
        </li>
        <li class="PageNav-item">
            <a href="#" class="PageNav-itemLink u-transitionAsm">Mina bilar</a>
        </li>
    </ul>
</nav>
</div>

*/

@import "../../common/variables.less";

@PageNav-borderColor: #F4F4F4;

.PageNav {
}

.PageNav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    float: left;
    font-weight: 300;
}

.PageNav-item {
    padding: 0;

    a {
        font-weight: 300;
    }
}

.PageNav-itemLink {
    color: #464646;
    text-decoration: none;
    font-size: 15/16em;

    &:hover {
        color: #515151;
        text-decoration: none;
    }

    i {
        color: #7C7C7C;
    }
}

.PageNav--horizontal {
    font-size: 12/16em;

    .PageNav-item {
        display: inline-block;


        &:last-child {
            margin-right: 0;
        }
    }

    .PageNav-itemLink {
        padding: 0.7em;
        display: inline-block;

        &.is-active {
            background: @colorGrey1;
        }

        &:hover {
            background: @colorGrey1;
        }
    }
}

.PageNav--vertical {


    .PageNav-item {
    }

    .PageNav-itemLink {
        display: block;
        padding: @spaceSmall 0;

        &.is-active {
            font-weight: bold;
        }
    }
}


.PageNav--large {
    font-size: @textSizeXLarge;
}
