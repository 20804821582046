@import "../../common/variables.less";

.Filter input[type="text"], .Filter input[type="password"] {
	color: black;
}

.login-text {
	.u-paddingLz;
	@media @bpSmall {
		.u-paddingLlg;
    }
}