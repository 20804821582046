/*#

Fade out content

Examples:
 
<div style="width:500px;">
      <div class="Fade js-fade" data-module="toggler" data-toggler="contentElement: '.js-fade', cssClass: 'Fade-show'">
        <p class="Fade-text">
            Aliquam hendrerit lectus magna, sit amet molestie ex convallis eu. Sed commodo, erat sed laoreet faucibus, justo nisi lacinia nisl, eu elementum orci quam in leo. Mauris nec tellus diam. Mauris laoreet leo sit amet diam faucibus porttitor. Sed a leo nulla. Cras nisl magna, tincidunt eu consequat nec, sodales a sem. Vestibulum viverra eget magna vel rutrum. Cras hendrerit egestas lorem, nec porttitor ante ultricies nec. Integer efficitur quis lorem at dapibus. Vestibulum non libero purus.
    Nunc viverra dictum ante at euismod. Maecenas in malesuada nisi, non sodales metus. Nulla rhoncus lectus eu hendrerit facilisis. Nulla vitae dui mattis, vestibulum tellus ut, molestie lorem. Quisque ac aliquet tortor. Suspendisse at tincidunt justo. Quisque ac enim quis nisi congue blandit. Aliquam eu dignissim neque. Nulla venenatis efficitur nisl quis porta. Etiam euismod ex tellus, ultricies volutpat sem dapibus in. Donec molestie venenatis sodales. Suspendisse at mauris porttitor, pretium est imperdiet, lobortis justo. Aenean cursus placerat tortor ut dapibus. Donec consectetur fringilla velit a tincidunt.
        </p>
        <div class="Fade-shadow"></div>
    </div>
    <div><br></div>
    <h2>with button (no padding):</h2>
      <div><br></div>
     <div class="Fade js-fade">
            <p class="FadeText-solid u-marginTz">
                Aliquam hendrerit lectus magna, sit amet molestie ex convallis eu. Sed commodo, erat sed laoreet faucibus, justo nisi lacinia nisl, eu elementum orci quam in leo. Mauris nec tellus diam. Mauris laoreet leo sit amet diam faucibus porttitor. Sed a leo nulla. Cras nisl magna, tincidunt eu consequat nec, sodales a sem. Vestibulum viverra eget magna vel rutrum. Cras hendrerit egestas lorem, nec porttitor ante ultricies nec. Integer efficitur quis lorem at dapibus. Vestibulum non libero purus.
                    Nunc viverra dictum ante at euismod. Maecenas in malesuada nisi, non sodales metus. Nulla rhoncus lectus eu hendrerit facilisis. Nulla vitae dui mattis, vestibulum tellus ut, molestie lorem. Quisque ac aliquet tortor. Suspendisse at tincidunt justo. Quisque ac enim quis nisi congue blandit. Aliquam eu dignissim neque. Nulla venenatis efficitur nisl quis porta. Etiam euismod ex tellus, ultricies volutpat sem dapibus in. Donec molestie venenatis sodales. Suspendisse at mauris porttitor, pretium est imperdiet, lobortis justo. Aenean cursus placerat tortor ut dapibus. Donec consectetur fringilla velit a tincidunt.
            </p>
            <div class="Fade-shadow"></div>
        </div>
        <a href="#" class="Button Button--transparantGreen" data-module="toggler" data-toggler="contentElement: '.js-fade', cssClass: 'Fade-show', addClassToSelf: true"><span class="Fade-more js-fade">Läs mer</span><span class="Fade-less js-fade">Dölj text</span></a>


</div>
*/

@import "../../common/variables.less";
.Fade {
    position: relative;
    height: 135px;
    overflow: hidden;
}
.DealerInfo .Fade {
	height: 250px;
}

.Fade-space {
    margin-bottom: @spaceLarge;

    @media @bpMedium {
        padding: 0 @spaceXLarge;
    }
}
.Fade-text {
    @media @bpLarge {
        padding:0 @spaceXLarge;
    }
}

.FadeText-solid {
    padding:0;
}

.Fade-show {
    height: auto !important;

    .Fade-shadow {
        display:none;
    }
}

.Fade-shadow {
background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
height:90px;
width:100%;
position:absolute;
bottom:0;
}

.Fade-less {
    display: none;

    &.Fade-show {
        display: inline;
    }
}

.Fade-more {
    display: inline;

    &.Fade-show {
        display: none;
    }
}