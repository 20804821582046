/*#

Form styling.

Examples:

<div>
    <!-- replace this comment with example HTML -->    
</div>

*/

@import "../../common/variables.less";
 
@Form-labelPadding: @spaceXSmall;
 #contact-dealer-form {
    .error {
        background-color: #ffd9c8;
    }
 }
.Form {
    margin-bottom: @spaceMedium;
    width: 100%;
    
    &.Form--fill {
        background: @colorGrey1;
        padding: @spaceMedium;
    }
    
    &.Form--bordered {
        border: 1px solid @colorGrey2;
        background-color: @colorGrey1;
        border-radius: 3px;
        padding: @spaceMedium;
    }
}

.Form-item { 
    display: block;
    width: 100%;
    padding-bottom: @spaceMedium;
}

.Form-label {
    color: @colorTextGrey;
    font-size: @textSizeSmall;
    margin-top:0.5em;
}
.Form-field { 
    font-size: @textSizeSmall;
    padding-top: 0.65em;
    padding-bottom: 0.65em;
    padding-left: 0.3em;
    padding-right: 0.3em;
}

.Form-label {
    padding: @Form-labelPadding;
    padding-top: 0;
    padding-left: 0;
}

.Form-field {}

@media @bpSmall {
    .Form--horizontal {
        display: table;
        caption-side: bottom;
        
        .Form-item {
            display: table-row;
            padding: 0;
            
            &.Form-item--submit {
                display: table-caption;
                width: 100%;
                padding-top: @spaceSmall;
                
                .Form-field {
                    display: block;
                    text-align: center;
                }
            }
        }
        
        .Form-label,
        .Form-field {
            display: table-cell;
            vertical-align: top;
            padding: @spaceSmall 0; 
        }
        
        .Form-label {
            padding-right: @spaceMedium;
        }
    }
}

#admin {
    /**
     * Select
     */
    .select {
        height: 30px;
        border-radius: 3px;

        display: inline-block;

        background: #fff;

        font-size: 12px;
        line-height: 26px;
    }

    .select > span {
        height: 26px;
        width: 100%;
        padding: 0 24px 0 10px;

        display: block;
        overflow: hidden;

        text-overflow: ellipsis;
    }

    .select.disabled {
        background: #eee;

        color: #999;
    }

    .select:after {
        height: 0;
        width: 0;
        border-top: 6px solid #000;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        margin-top: -3px;

        content: " ";
        display: block;
        position: absolute;
        top: 50%;
        right: 8px;
    }

    .select.disabled:after {
        border-top-color: #999;
    }

    .select select {
        height: 30px;
        width: 100%;
        border: 0;

        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;

        opacity: 0;
        filter: alpha(opacity=0);

        font-size: 13px;
        line-height: 26px;
        vertical-align: top;
    }

    .select .value {
        color: #999;
    }

    .select.multiple ul {
        max-height: 190px;
        border-radius: 3px;

        display: none;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 101;
        position: absolute;
        left: 0;

        background: #fff;
        box-shadow: 0 1px 4px rgba(0, 0, 0, .6);
    }

    .select.multiple ul.down {
        top: 0;
        width: 210px;
    }

    .filter-multi-select{
        width: 200px !important;
    }


    /**
    * Show scrollbars in divs
    */

    .select.multiple ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    .select.multiple ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(90,90,90,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }


    .select.multiple ul.up {
        bottom: 0;
    }

    .select.multiple ul li label {
        width: auto;
        padding: 0 5px;

        display: inline-block;

        font-size: 12px;
        white-space: nowrap;
    }

    .select.multiple ul li label input[type=checkbox] {
        margin-right: 3px;
    }

    /**
     * Input
     */
    input[type=text],
    input[type=phone],
    input[type=email],
    input[type=password],
    textarea {
        height: 30px;
        border: 0;
        padding: 0 10px;
        margin: 0 0 10px 0;
        border-radius: 3px;

        background: #fff;

        vertical-align: middle;
    }

    /**
     * Textarea
     */
    textarea {
        height: auto;
        padding: 10px;

        resize: none;
    }

    /**
     * Label
     */
    label {
        font-size: 15px;
    }

    /**
     * Label checkbox
     */
    .checkbox {
        height: 26px;

        display: block;

        font-size: 15px;
        line-height: 26px;
        vertical-align: middle;
    }

    /**
     * Error
     */
    .error {
        background-color: #fde3e3 !important;
    }

    /**
     * Button
     */
    .button {
        height: 36px;
        border: 0;
        border-radius: 3px;
        border: 1px solid #419942;
        padding: 0 15px;

        cursor: pointer;
        float: right;

        background: rgb(53,180,74); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(53,180,74,1) 0%, rgba(42,146,60,1) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(53,180,74,1)), color-stop(100%,rgba(42,146,60,1))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(53,180,74,1) 0%,rgba(42,146,60,1) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(53,180,74,1) 0%,rgba(42,146,60,1) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(53,180,74,1) 0%,rgba(42,146,60,1) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(53,180,74,1) 0%,rgba(42,146,60,1) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35b44a', endColorstr='#2a923c',GradientType=0 ); /* IE6-9 */

        color: #fff;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        text-transform: uppercase;
        text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    }

    .button.full {
        width: 100%;

        text-align: center;
    }

    a.button {
        line-height: 36px;
    }

    a.button:hover {
        color: #fff;
    }

    .button.small {
        padding: 0 5px;

        font-size: 13px;
    }

    /**
     * Action bar
     */
    .action-bar {
        margin-bottom: 20px;
        height: 32px;
        width: 100%;
        padding-bottom: 20px;
    }

    .action-bar .left {
        float: left;
    }

    .action-bar .right {
        float: right;
    }

    .action-bar input {
        float: left;
    }

    /**
     * Layout
     */
    .form {
        width: 100%;
    }

    .form.two-col .form-row {
        width: 50%;

        float: left;
    }

    .form-row label {
        padding-bottom: 5px;

        display: block;
    }

    .form-row label .req {
        color: red;
    }

    .form-row input[type=text],
    .form-row input[type=phone],
    .form-row input[type=email],
    .form-row input[type=password],
    .form-row textarea {
        width: 300px;
        border: 1px solid #ccc;
    }

    .form-row select,
    .form-row input[type=checkbox] {
        margin-bottom: 10px;
    }

    .checkbox-list {
        width: 100%;
    }

    .checkbox-list label {
        width: 50%;

        display: block;
        float: left;
    }

    .form-actions {
        padding: 20px;
        width: 100%;
        margin: 20px 0;
        border-radius: 5px;

        display: block;

        background: #ddd;
    }

    /**
     * IE placeholder
     */
    input.placeholder, textarea.placeholder {
        color: #aaa;
        font-size: 12px;
    }

    /**
     * Flat button
     */
    .flat-button {
        height: 32px;
        padding: 0 15px;
        border-radius: 3px;
        border: 0;
        cursor: pointer;

        display: inline-block;

        background: #3d87cf;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .4);
        border: 0;

        cursor: pointer;
        color: #fff !important;
        font-size: 14px;
        font-weight: normal;
        line-height: 32px;
        text-decoration: none;
        text-shadow: 0 1px 1px rgba(0, 0, 0, .4);
    }

    .flat-button:disabled{
        background:#eee;
    }

    .flat-button:disabled:hover{
        background:#eee;
    }


    .flat-button.big {
        height: 40px;

        font-size: 16px;
        line-height: 40px;
    }

    button.flat-button {
        border: 0;
        margin: 0;
    }

    .flat-button:hover {
        background: #4F98E0;

        color: #fff;
    }

    .flat-button.white {
        background: #fff;

        color: #262626 !important;
        text-shadow: none;
        font-weight: normal;
    }

    .flat-button.white:hover, .flat-button.white.active {
        background: #eee;

        color: #262626;
    }

    .flat-button.red {
        background: #cc6666;
    }

    .flat-button.red:hover {
        background: #ff6666;
    }

    /**
     * Round button
     */
    .round-button {
        width: 18px;
        height: 18px;
        border-radius: 9px;

        display: inline-block;

        background: #282828;

        color: #fff !important;
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        text-align: center;
        text-decoration: none;
    }

    .round-button:hover {
        color: #fff;
    }

    /**
     * Flat select
     */
    .flat-select {
        height: 32px;
        padding: 0 0 0 10px;
        border: 1px solid #d9d9d9;
        border-bottom-color: #999;
        border-radius: 3px;

        display: inline-block;

        background: #fff;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .3);

        color: #262626;
        font-size: 13px;
        font-weight: bold;
        line-height: 30px;
    }

    .flat-select:before, .flat-select:after {
        border: 6px solid transparent;
        border-left-width: 4px;
        border-right-width: 4px;
        border-bottom-color: #bbb;

        content: " ";
        display: block;
        position: absolute;
        top: 1px;
        right: 10px;
    }

    .flat-select:after {
        border-top-color: #bbb;
        border-bottom-color: transparent;

        top: 18px;
    }

    .flat-select select {
        height: 32px;
        width: 100%;
        border: 0;

        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;

        opacity: 0;
        filter: alpha(opacity=0);

        font-size: 13px;
        line-height: 26px;
        vertical-align: top;
    }

    /**
     * Flat select date range
     */
    .flat-select.select-date-range {
        width: 220px;
        padding-right: 40px
    }

    .flat-select.select-date-range:before {
        display: none;
    }

    .flat-select.select-date-range:after {
        height: 20px;
        width: 20px;
        border: 0;

        top: 5px;
        right: 10px;

        background: url(../img/admin-icon/calendar-gray.png) center center no-repeat;
    }

    .flat-select.select-date-range > a {
        width: 100%;
        height: 100%;

        z-index: 1;
        display: block;
        position: absolute;
        top: 0;
        left: 0;

        opacity: 0;
    }

    .flat-select.select-date-range > div {
        width: 100%;
        padding: 8px 5px 5px 5px;
        border: 1px solid #ddd;
        border-top: 0;
        border-radius: 0 0 3px 3px;

        display: none;
        z-index: 100;
        position: absolute;
        top: 32px;
        left: 0;

        background: #f5f5f5;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .3);
    }

    .flat-select.select-date-range > div input {
        width: 93px;
        margin: 0;
        border: 1px solid #ddd;

        background: #fff;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    }

    .flat-select.select-date-range > div .separator {
        height: 32px;
        width: 20px;

        display: block;
        float: left;

        text-align: center;
    }

    /**
     * Flat input
     */
    .flat-input[type=text], .flat-input[type=password], textarea.flat-input {
        height: 32px;
        padding: 0 10px;
        border: 1px solid #d9d9d9;
        border-radius: 3px;

        background: #fff;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .4), 0 1px 1px #fff;

        color: #262626;
        font-size: 13px;
        vertical-align: middle;
    }

    .flat-input.suffix {
        padding: 0 30px 0 10px;
    }

    .flat-input.small {
        width: 33% !important;
    }

    .flat-input.medium {
        width: 65% !important;
    }

    .flat-input.disabled, .flat-select.disabled {
        background: #f1f1f1;
    }

    .flat-input.disabled {
        box-shadow: none;
    }

    textarea.flat-input {
        height: auto;
        padding: 10px;
    }

    .input-suffix {
        width: 32px;
        height: 32px;

        display: block;
        content: " ";
        position: absolute;
        bottom: 10px;
        right: 20px;

        color: #999;
        font-size: 13px;
        text-align: center;
        line-height: 32px;
    }

    /**
     * Large option block
     */
    .large-option-block {
        width: 220px;
        height: 220px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;

        cursor: pointer;
        display: block;

        background-color: #fff;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .3);
        display: block !important;
    }

    .large-option-block.checked {
        background-color: #f9f9f9;
        box-shadow: inset 0 0 20px rgba(0, 0, 0, .1), 0 1px 1px rgba(0, 0, 0, .3);
    }

    .large-option-block input {
        opacity: 0;
    }

    .large-option-block.photo {
        background-image: url(../img/admin-icon/photo.png);
        background-position: center center;
        background-repeat: no-repeat;
    }

    .large-option-block.photo span {
        height: 20px;
        width: 100%;

        position: absolute;
        top: 125px;
        left: 0;

        text-align: center;
    }

    /**
     * Large radio block
     */
    .large-radio-block {
        height: 73px;
        margin-bottom: 20px;
        border-radius: 5px;

        box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    }

    .large-radio-block label {
        height: 73px;
        width: 117px;
        border: 1px solid #d9d9d9;
        border-right-width: 0;

        cursor: pointer;
        display: block;
        float: left;

        background: #fff;
    }

    .large-radio-block label.selected {
        width: 117px;
        border-top-color: #b1b1b1;
        border-bottom-color: #b1b1b1;

        background-color: #f9f9f9;
        box-shadow: inset 0 0 20px rgba(0, 0, 0, .1);
    }

    .large-radio-block label input {
        cursor: pointer;

        opacity: 0;
    }

    .large-radio-block label:first-child {
        border-radius: 5px 0 0 5px;
    }

    .large-radio-block label:last-child {
        border-radius: 0 5px 5px 0;
        border-right-width: 1px;
    }

    .large-radio-block.type span {
        width: 100%;
        margin-top: 18px;
        padding-top: 24px;

        display: block;
        position: absolute;
        top: 0;
        left: 0;

        background: url(../img/search-icon/car.png) center top no-repeat;

        font-size: 12px;
        font-weight: bold;
        text-align: center;
    }

    .large-radio-block.type span.transport {
        background-image: url(../img/search-icon/transport.png);
    }

    .large-radio-block.type span.housewagon {
        background-image: url(../img/search-icon/housewagon.png);
    }

    .large-radio-block.type span.mc {
        background-image: url(../img/search-icon/mc.png);
    }

    /* ! Option list */
    ul.option-list {
        font-size: 14px;
        list-style-type: none;
        margin: 20px 0;
        overflow: hidden;
    }
    ul.option-list li {
        cursor: pointer;
        display: block;
        float: left;
        margin: 5px 20px 20px 0;
        text-align: center;
        width: 140px;
    }
    ul.option-list li [type=radio] {
        display: none;
    }
    ul.option-list li:before {
        border-radius: 3px;
        background-image: url( ../img/admin/icons.png );
        background-color: white;
        box-shadow: 0 1px 3px rgba( 0, 0, 0, 0.5 );
        content: '';
        display: inline-block;
        margin: 5px;
    }
    ul.option-list li.selected:before {
        border-radius: 5px;
        border: 3px solid #3d87cf;
        box-shadow: 0 3px 6px rgba( 0, 0, 0, 0.25 );
        margin: 2px;
    }
    ul.option-list.ads li {
        margin: 5px 5px 5px 0;
        width: 110px;
    }
    ul.option-list.ads li:before {
        height: 100px;
        width: 80px;
    }
    ul.option-list .ad.magazine:before {
        background-position: 0 -620px;
    }
    ul.option-list .ad.multiple:before {
        background-position: -80px -620px;
    }
    ul.option-list .ad.halfpage:before {
        background-position: -160px -620px;
    }
    ul.option-list .ad.halfpage_multiple:before {
        background-position: -240px -620px;
    }

    #motorborsen-form {
        background: #d8d8d8;
        border-bottom: 2px solid #bfbfbf;
        position: absolute;
        width: 940px;
    }
    #motorborsen-form .inner {
        position: relative;
    }
    #motorborsen-form .inner:before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #d8d8d8;
        content: '';
        display: block;
        height: 0;
        left: 60px;
        position: absolute;
        top: -25px;
        width: 0;
    }
    #motorborsen-form .small {
        font-size: 12px;
    }
    #motorborsen-form {
        padding: 15px;
    }
    #motorborsen-form .close-form {
        background: url( ../img/admin/icons.png ) 0 -20px;
        display: block;
        height: 20px;
        opacity: 0.4;
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
        z-index: 100;
    }
    #motorborsen-form .close-form:hover {
        opacity: 1;
    }
    #motorborsen-form .left,
    #motorborsen-form .right {
        overflow: hidden;
        margin-bottom: 10px;
    }
    #motorborsen-form .left {
        float: left;
        border-right: 2px solid #bababc;
    }
    #motorborsen-form .right {
        float: right;
        margin-left: 10px;
        width: 48%;
    }
    #motorborsen-form label {
        display: block;
        margin: 20px 0;
    }
    #motorborsen-form .flat-select {
        width: 430px;
    }
    #motorborsen-form .flat-button {
        float: right;
    }
    #motorborsen-form .summary {
        float: right;
        margin: 7px 20px 0 0;
    }
    #motorborsen-list tr {
        cursor: pointer;
    }
    #motorborsen-list tr.edit td {
        padding-top: 20px;
        padding-bottom: 275px;
    }

}

.InputAddOn {
  display: flex;
  border-radius: 4px;
}

input.InputAddOn-field {
    flex: 1;
    border-radius: 4px 0 0 4px;
    border-right: 0 !important;

    &::-webkit-input-placeholder {
        color: @colorTextLighter;
    }

    &:-moz-placeholder {
        color: @colorTextLighter;
        opacity:  1;
    }

    &::-moz-placeholder {
        color: @colorTextLighter;
        opacity:  1;
    }

    &:-ms-input-placeholder {
        color: @colorTextLighter;
    }

    &::-ms-input-placeholder {
        color: @colorTextLighter;
    }
}

.InputAddon-item {
    border-radius: 0 4px 4px 0;
    border: 1px solid @colorGrey2;
    border-left: none;
}

.js-showForm {
    display: none;
}

.js-showForm.is-active {
    display: block;
}