@import "../../common/variables.less";

/*#
Component used to display status messages.

<div class="Status"><p>This is plain status message</p></div>
<div class="Status Status--success"><p>This is a success message</p></div>
<div class="Status Status--info"><p>This is an info message</p></div>
<div class="Status Status--warning"><p>This is a warning message</p></div>

*/

.Status {
    padding: 0.1em 1em;
    margin: 1em 0;
    background-color: #fff;
}

.Status--rounded {
    border-radius: 1em;
}

.Status--padded {
    padding: 1.5em;
    margin:0px 0px 20px 0px;
}

.Status > ul {
    padding: 0px;
    margin: 5px 0px 0px 0px;
    list-style: inside;
}

.Status--fixedBottom {
    margin: 0;
    position: fixed;
    backface-visibility: hidden; /* 1 */
    bottom: 0;
    right: 0;
    z-index: 10;
}

.Status--center {
    text-align: center;
}

.Status--transparent {
    background-color: transparent;
}

.Status--tight {
    padding-right: 0;
    padding-left: 0;
}

.Status--condensed {
    padding-right: 0.4em;
    padding-left: 0.4em;
}

.Status--success {
    background-color: #E2F9BE;
}

.Status--info {
    background-color: #FFF6CB;
}

.Status--warning {
    background-color: #ffd9c8;
}

.Status--successflash {
    color: @colorLink;
    border: 1px solid #D2EAC3;
    background-color: #DCF1D6;
}
