@import "../../common/variables.less";

#object-compare-wrapper{
    .u-posFixed;
    bottom: 0;
    display: none;
    height: 7em;
    margin: 0 auto;
    width: 100%;
    z-index: 1000;
    @media (max-width: 920px) {
        height: 8em;
    }
    @media (max-width: 630px) {
        height: 5em;
    }
    
}

#object-compare {
    background: @colorGrey1 none repeat scroll 0 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
    display: none;
    height: 7em;
    margin: 0 auto;
    padding: 10px;
    width: 58em;
    z-index: 1000;
    @media (max-width: 920px) {
        width: 100%;
        height: 8em;
    }
    @media (max-width: 630px) {
        height: 5em;
    }
    .objects {
        float: left;
        min-width: 343px;
    }
    .object {
        float: left;
        margin-right: 7px;
        text-align: center;
        width: 8em;
        @media (max-width: 920px) {
            width: 9em;
        }
        @media (max-width: 840px) {
            width: 8em;
        }
        @media (max-width: 780px) {
            width: 7em;
        }
        @media (max-width: 720px) {
            width: 6em;
        }
        @media (max-width: 630px) {
            width: 4em;
        }
        @media (max-width: 450px) {
            width: 3em;
            margin-top: 6px;
        }
        
        .object-delete {
            position: relative;
            img {
                position: absolute;
                right: 23px;
                top: -70px;
                width: 12px !important;
                height: 12px !important;
                @media (max-width: 780px) {
                    right: 10px;
                    top: -69px;
                }
                @media (max-width: 780px) {
                    right: 10px;
                }
                @media (max-width: 720px) {
                    right: 2px;
                }
                @media (max-width: 630px) {
                    right: -3px;
                    top: -48px;
                }
                @media (max-width: 450px) {
                    right: -5px;
                    top: -42px;
                }
            }
        }
        h3 {
            color: @colorText;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            @media (max-width: 630px) {
                .u-hidden;
           }
        }
        img {
            cursor: pointer;
            margin-top: 3px;
            @media (max-width: 630px) {
                height: 41px;
            }
            @media (max-width: 450px) {
                height: 33px;
                widht: 40px;
            }
            
        }
    }
    .button-wrapper {
        .u-marginLlg;
        .u-pullLeft;
        width: 8em;
        .u-marginTxsm;
        @media (max-width: 920px) {
            margin-top: 1em !important;
       }
       @media (max-width: 630px) {
            font-size: xx-small;
            .u-marginTz;
       }
        @media (max-width: 450px) {
            font-size: xx-small;
            .u-marginTz;
            margin-left: 0.5em !important;
       }
       
    }
    .compare-button {
        .Button;
        .Button--gradient;
        width: 8em;
    }
    #button-compare {
        .Button; 
        .Button--transparantGreen;
        width: 8em;
        .u-marginTsm;
    }
    .close {
        float: right;
        margin-top: 6px;
        @media (max-width: 920px) {
            position: absolute;
            right: -2px;
            top: -40px;
       }
        img {
            cursor: pointer;
            margin-top: 14px;
        }
    }
}



