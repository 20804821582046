/** mdl ripple effect not working on iOS 13 **/
/* Here's custom ripple solution */
.custom-ripple {
  background-position: center !important;
  transition: background 0.8s;

  &:hover {
    background: #0b87c4 radial-gradient(circle, transparent 1%, #0b87c4 1%) center/15000% !important;
  }

  &:active {
      background-color: #0b87c4 !important;
      background-size: 100%;
      transition: background 0s;
    }
}