/*#

Styling for links and button elements.

Examples:
<div>
    <button class="Button Button--gradient">Button</button>
    <a href="#" class="Button Button--gradient">Link Button</a>
</div>
<div><br /></div>
<div>
    <button class="Button Button--gradient Button--upper Button--padding">Sok</button>
    <a href="#" class="Button Button--gradient Button--upper Button--padding">Sok</a>
</div>

<div><br /></div>
<div>
    <button class="Button Button--gradientLarge">Button</button>
    <a href="#" class="Button Button--gradientLarge">Link Button</a>
</div>
<div><br /></div>
<div>
    <button class="Button Button--square">Button</button>
    <a href="#" class="Button Button--square">Link Button</a>
</div>

<div><br /></div>
<div>
    <button class="Button Button--squareGreen">Button</button>
    <a href="#" class="Button Button--squareGreen">Link Button</a>
</div>
<div><br /></div>
<div>
    <button class="Button Button--square">Compare <i class="Icon Icon--checkmark"></i></button>
    <a href="#" class="Button Button--square">Compare <i class="Icon Icon--checkmark"></i></a>
     <a href="#" class="Button Button--square active">Compare <i class="Icon Icon--checkmark"></i></a>
</div>

<div><br /></div>
<div style="background: #E9E7EA; padding:5px;">
    <button class="Button Button--transparant">Button</button>
    <a href="#" class="Button Button--transparant">Button</a>

</div>

<div><br /></div>
<div>
    <button class="Button Button--transparantGreen">Button</button>
    <a href="#" class="Button Button--transparantGreen">Button</a>

</div>
*/

@import "../../common/variables.less";




.Button {
    display: inline-block;
    -webkit-appearance: none;
    cursor: pointer;
    line-height: 1em; // adjusts so that link button and <button> is equal height
    margin: 0;
    padding: .5em 1.3em;
    font-size: inherit;
    text-align: center;
    text-decoration: none;
    white-space: normal;
    user-select: none;
    vertical-align: middle;
    background: none;
    border: none;
    color: #fff;
    border-radius: 10px;
    display:inline-block;

    &:hover {
        color: #fff;
        text-decoration:none;

    }
}
#dealers .Button{
	padding: 0.5em;
	font-size: 0.95em;

	@media @bpMedium {
		font-size: 1em;
	}
}

.sell_car.Button{
    top: 9px;
    right: 78px;

    @media @bpXSmall {
        right: 85px;
    }

    @media @bpSmall {
        top: 7px;
        right: 135px;
    }

    @media @bpMedium {
        top: 9px;
        padding-top: 0.55em !important;
    }

    @media @bpLarge {
        right: 140px
    }

    &:hover {
        background: @colorLinkHover;
    }
}

.Button--gradient {
    background: #05842a; /* Old browsers */
    background-image: linear-gradient(-180deg, @colorLightGreen 0%, @colorGreen 100%);
    background: -moz-linear-gradient(top, @colorLightGreen 0%, @colorGreen 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, @colorLightGreen 0%, @colorGreen 100%); /* Chrome10-25,Safari5.1-6 */
    border: 2px solid #32A845;

    &:hover {
        background: @colorGreen;
    }
}

.Button--gradientLarge {
    background: #05842a; /* Old browsers */
    background-image: linear-gradient(-180deg, @colorLightGreen 0%, @colorDarkGreen 100%);
    background: -moz-linear-gradient(top, @colorLightGreen 0%, @colorDarkGreen 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, @colorLightGreen 0%, @colorDarkGreen 100%); /* Chrome10-25,Safari5.1-6 */
    border: 2px solid #32A845;
    padding: 15px 25px;
    border-radius: 13px;
    font-size: 21/16em;

    &:hover {
        background: @colorDarkGreen;
    }
}


.Button--square {
    border-radius: 4px !important;
    border-radius: 0;
    border: 1px solid @colorButton;
    color: #515151;
    background: #fff;

    i {
         font-size: 10px;
        @media @bpMedium {
            color: #e7e7e7;
            font-size: 14px;
        }
    }

    &:hover {
        background: @colorGrey2;
        color: #515151;

        i {
            color: #ddd;
        }
    }

    &.active, &:active {
        background: @colorButton;
        color: #fff;

        i {
            color: #fff;

            &:hover {
                color: #fff;
            }
        }
    }
}

.Button--rounded {
    border-radius: 50px !important;
    border: 1px solid @colorButton;
    color: #515151;
    background: #fff;

    i {
         font-size: 10px;
        @media @bpMedium {
            color: #e7e7e7;
            font-size: 14px;
        }
    }

    &:hover {
        background: @colorGrey2;
        color: #515151;

        i {
            color: #ddd;
        }
    }

    &.active, &:active {
        background: @colorButton;
        color: #fff;

        i {
            color: #fff;

            &:hover {
                color: #fff;
            }
        }
    }
}

.Button--roundedBlue {
    border-radius: 50px;
    background: #1AB0ED;
    border: 0px;
    font-size: inherit;
    font-weight: 300;
    padding: 17px 40px 15px 40px;
    transition: background-color .3s;
    overflow: hidden;
    display: inline-block;
    position: relative;

    &:hover {
        background: #1F94CE;
        background-color: #0b87c4;
    }

    &:focus {
        background: #1F94CE;
        background-color: #0b87c4;
    }

    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
          
        display: block;
        width: 0;
        padding-top: 0;
            
        border-radius: 100%;
          
        background-color: rgba(236, 240, 241, .3);
          
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    &:active:before {
        width: 120%;
        padding-top: 120%;
      
        transition: width .2s ease-out, padding-top .2s ease-out;
    }
}

.Button--squareBlue {
    border-radius: 4px;
    background: @colorButton;
    border: 1px solid @colorButton;


    &:hover {
        background: @colorButtonHover;
    }
}

.Button--squareGreen {
    border-radius: 4px;
    background: @colorLightGreen;
    border: 1px solid @colorLightGreen;


    &:hover {
        background: @colorLinkHover;
    }

}

.Button--roundedGreen {
    border-radius: 50px;
    background: @colorLighterGreen;
    border: 1px solid @colorLighterGreen;


    &:hover {
        background: @colorLinkHover;
        border: 1px solid @colorLightGreen;
    }

}

.Button--squareGrey {
    border-radius: 0;
    background: @colorGrey2;
    border: 1px solid @colorGrey2;
    color: @colorGrey4;

    &:hover {
        background: @colorGrey3;
        border: 1px solid @colorGrey3;

    }
}




.Button--transparant {
    border: 2px solid #fff;
    color: #fff;

    &:hover {
        background: @colorLightGreen;
    }
}

.Button--transparantGreen {
    border:2px solid #b1e5ba;
    color: @colorLightGreen;

    &:hover {
        background: @colorLightGreen;
    }
}

.Button--transparantGreenNoHover {
    border:2px solid #b1e5ba;
    color: @colorLightGreen;

    &:hover {
        color: @colorLightGreen;
    }
}

.Button--transparantGreenHover {
    border:1px solid #b1e5ba;
    color: @colorLightGreen;
    background: #fff;

    &:hover {
        color: @colorLightGreen;
    }
}

.Button--upper {
    text-transform:uppercase;
}
.Button--padding {
     padding: .5em 3em;
}
.Button--paddingVmd {
     padding: 1em 2em;
}
.Button--padding-sm {
     padding: .5em 1em;
}

.Header-menuButton {
    font-size: 20px;
    color: #000;
    border: none;
    padding: @spaceXXXSmall @spaceXSmall @spaceXSmall @spaceXSmall;

    &:hover {
        color: @colorTextGrey;
    }

    @media @bpXSmall {
        padding-right: @spaceMedium;
    }
}

.Button--shadow {
    box-shadow: 2px 2px 1px 0px rgba(0,0,0,.25)
}
.Button-wrapper-list {
    margin-left:@spaceSmall;

    @media @bpXSmall {
        padding-left: @spaceSmall;

    }

}

.Button--trading {
    width:70% !important;
    margin-left: 15%;
    margin-right: 15%;


    @media @bpSmall {
        width:90% !important;
        margin-left: 0;
        margin-right: 0 ;
    }

    @media @bpMedium {
        width:80% !important;
    }

    @media @bpLarge {
        width:70% !important;
    }
}

.Button--minPadding {
    padding: .5em;

    @media @bpXSmall {
        padding: .5em .5em;

    }
}

.Button--dealerlist {
    width: 9em !important;

    &:disabled {
        background-color: @colorGrey6;
    }
}
