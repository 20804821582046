@import (reference) "../common/variables.less";
/**
 * set borders on elements
 */

.u-borderA {
	border:1px solid @borderColor;
}

.u-borderV {
    border-top:1px solid @borderColor;
    border-bottom: 1px solid @borderColor;
}

.u-borderH {
    border-left:1px solid @borderColor;
    border-right: 1px solid @borderColor;
}

.u-borderT {
    border-top:1px solid @borderColor;
}

.u-borderB {
    border-bottom:1px solid @borderColor;
}

.u-borderL {
    border-left:1px solid @borderColor;
}

.u-borderR {
    border-right:1px solid @borderColor;
}

.u-borderAz {
	border: 0px !important;
}

.u-borderVz {
	border-top: 0px !important;
	border-bottom: 0px !important;
}

.u-borderHz {
	border-left: 0px !important;
	border-right: 0px !important;
}

.u-borderTz{
	border-top: 0px !important;
}

.u-borderBz{
	border-bottom: 0px !important;
}

.u-borderLz{
	border-left: 0px !important;
}

.u-borderRz{
	border-right: 0px !important;
}


.u-border--sm {
    border-width:2px !important;
}

.u-border--xlarge {
    border-width:7px!important;
}

.u-border--green{ 
    border-color: @colorLightGreen!important;
}

.u-borderRadiusz{ 
    border-radius: 0;
}

.u-borderRadiusCircle{ 
    border-radius: 50%;
}


.u-borderRoundsm{ 
    border-radius: 3px;
}
.u-borderRoundmd{ 
    border-radius: 5px;
}

.u-borderRoundBsm{
    border-radius: 0 0 3px 3px;
}
.u-borderRoundBmd{
    border-radius: 0 0 5px 5px;
}
.u-md-borderRoundTmd{
    @media @bpMedium {
        border-radius: 5px 5px 0 0;
    }
}
.u-borderWidth1{
    border-width: 1px;
}

.u-borderWhite {
    border-color: #fff !important;
}

.u-md-borderA {
    @media @bpMedium {
        border:1px solid @borderColor !important;
    }
}