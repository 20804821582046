/*#

Styling for SaldoList.

Examples:
<div style="">
  <ul class="SaldoList List List--vertical u-colorTextGrey u-textWeightMedium">
    <li class="List-item"><a href="#">Alfa Romeo</a> (33)</li>
    <li class="List-item"><a href="#">Alpine</a> (3)</li>
    <li class="List-item"><a href="#">Aston Martin</a> (3)</li>
    <li class="List-item"><a href="#">Audi</a> (3)</li>
    <li class="List-item"><a href="#">Bentley</a> (3)</li>
    <li class="List-item"><a href="#">BMW</a> (3)</li>
    <li class="List-item"><a href="#">Cadillac</a> (3)</li>
    <li class="List-item"><a href="#">Chevrolet</a> (22)</li>
    <li class="List-item"><a href="#">Chrysler</a> (3)</li>
    <li class="List-item"><a href="#">Citro�n</a> (13)</li>
    <li class="List-item"><a href="#">Dacia</a> (3)</li>
    <li class="List-item"><a href="#">Daewoo</a> (3)</li>
    <li class="List-item"><a href="#">Dodge</a> (3)</li>
    <li class="List-item"><a href="#">Ferrari</a> (401)</li>
    <li class="List-item"><a href="#">Fiat</a> (0)</li>
    <li class="List-item"><a href="#">Honda</a> (3)</li>
    <li class="List-item"><a href="#">Hyundai</a> (2)</li>
    <li class="List-item"><a href="#">Jaguar</a> (3)</li>
    <li class="List-item"><a href="#">JEEP</a> (3)</li>
    <li class="List-item"><a href="#">Kia</a> (1)</li>
    <li class="List-item"><a href="#">Koenigsegg</a> (3)</li>
</ul>
</div>
*/
@import "../../common/variables.less";
.SaldoList li {
    font-size: 14/16em;
    line-height: 15px;
    float: none;
    width:auto;
    margin: 2px 0px 8px 0;
    /*width:30%;*/
        @media @bpMedium {
            font-size: 13/16em;
            margin: 2px 0px 10px 0;
            float: none;
            width:auto;
        }


    a {
        border-bottom: 1px solid @colorLink;
        padding-bottom: 1px;

        &:hover {
            text-decoration: none;
        }
    }
}
.md-noBorder{
    border-left: none !important;
    @media @bpMedium {
        border-left:1px solid @borderColor !important;
    }
}