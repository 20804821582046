/*#
Custom styling for Select drodowns. Supports focus and disabled states.
Examples:
<div>
    <span class="Select u-sm-size1of3X">
        <select class="Select-input">
            <option>Select an option</option>
            <option>Invoice</option>
            <option>Emergency</option>
            <option>Question</option>
            <option>A very long item is here that will never end</option>
            <option>Other</option>
        </select>
    </span>
</div>
*/

@import "../../common/variables.less";

    /*
    
   
   
    background-image: url('/assets/images/selectArrow.png');
    background-repeat: no-repeat;
    background-position: right center;
    border: none;*/

.Select {
    position: relative;
    padding: 0;
    display: inline-block;
    background: #fff; 
    border: none;
    border-radius: 5px;
    box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.42);
    -webkit-box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.42);
    -moz-box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.42);
    &:after {
        content: "";
        position: absolute;
        width: 2.2em;
        top: 1px;
        bottom: 1px;
        right: 1px;
        background-image: url('/assets/images/selectArrow.png');
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 31px 23px;
        background-position: center;
        pointer-events: none;
        z-index: 2;
    }
}

.Select-input {
    width: 100%;
    margin: 0;
    background: none;
    border: 1px solid transparent;
    outline: none;
    cursor: pointer;
    padding: 0.14em 2em 0.14em 0.6em;
    border-radius: 5px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    
    
    &:hover {
        box-shadow: 0 0 1px 1px darken(@colorGrey1, 15%);
    }
    
    &:focus {
        outline: none;
    }
    
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        
        &:hover {
            box-shadow: none;
        }
    }
}

.is-no-appearance {
    .Select {
        overflow: hidden;
    }
    
    .Select-input {
        width: ~"calc(100% + 60px)";
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    // IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance, 
    // IE 9 and earlier get a native select - targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5
    .Select-input::-ms-expand {
        display: none;
    }

    // This removes the odd blue bg color behind the text in the select button in IE 10/11 
    // and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box
    .Select-input:focus::-ms-value {
        background: transparent;
        color: @colorText;
    }
}
