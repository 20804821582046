/*#

Styling for links and button elements.

Examples:
<div style="background: #f5f5f5; padding:10px;">
    <div class="ButtonBar">
            <a href="#" class="Button Button--transparant ButtonBar-button active">Begagnade</a>
            <a href="#" class="Button Button--transparant ButtonBar-button">Nya</a>
            <a href="#" class="Button Button--transparant ButtonBar-button">Leasing</a>
            <a href="#" class="Button Button--transparant ButtonBar-button">Bilhandlaren</a>
    </div>
</div>
*/
@import "../../common/variables.less";

.ButtonBar {
    border: 1px solid @colorLightGreen;
    display: inline-block;
    width:100%;
    margin-top: @spaceSmall;
    @media @bpXSmall {
          border-radius: 10px;
          border: 2px solid #fff;
          width:auto;
          margin:0;
    }
}

.ButtonBar-button {
    border: none;
    border-right: 1px solid @colorLightGreen;
    border-radius: 0;
    color: @colorLink;
    padding: @spaceSmall 0;
    float: left;
    width: 20%;
    background-color: #fff;

    &:first-child {
        width: 30%;
    }

    &:last-child {
        width: 30%;
    }

    @media @bpXSmall {
       padding: .5em;
        border-right: 2px solid #fff;
        width:auto;
        background-color:transparent;
         &:first-child {
            width: auto;
        }

        &:last-child {
            width: 31%;
        }
    }

    @media @bpMedium {
          padding: .5em 1.2em;
    }

    @media @bpLarge {
          padding: .5em 1.3em;
    }

    &:hover {
        background: @colorGrey2;
    }

    &.active {
        background: @colorLightGreen;
        color: #fff;
    }

    &:first-child {
        @media @bpXSmall {
            &:hover, &.active {
                border-radius: 10px 0 0 10px;
            }
        }
    }

    &:last-child {
        border: none;

        &:hover, &.active {

            @media @bpXSmall {
                border-radius: 0 10px 10px 0;
            }
        }
    }
}
