/*#

Give a short description of the component's use case and its options.
Markdown is allowed for rich editing.

Examples:

<div>
    <h1>Heading 1</h1>
    <h2>Heading 2</h2>
    <h3>Heading 3</h3>
    <h4>Heading 4</h4>
    <h5>Heading 5</h5>
    <h6>Heading 6</h6>
</div>

*/

@import (reference) "../../common/variables.less";

@Heading-color: #262626;
@Heading-size1: 44px;
@Heading-size2: 30px;
@Heading-size3: 25px;
@Heading-size4: 22px;
@Heading-size5: 16px;
@Heading-size6: 14px;

.Heading {
    margin-top: 0;
    margin-bottom: .5em;
    color: @Heading-color;
    font-weight: 400;

    a:hover & {
        color: @colorLinkHover;
    }
}

.Heading--h1, h1 {
    &:extend(.Heading all);

    font-size: unit(@Heading-size1/@baseFontSize, em);
}

.Heading--h1--small {
    font-size: unit(@Heading-size5/@baseFontSize, em);
    color: #404040;
    margin-bottom: 2em;
}

.Heading--h2, h2 {
    &:extend(.Heading all);
    font-size: unit(@Heading-size2/@baseFontSize, em);
}

.Heading--h3, h3 {
    &:extend(.Heading all);

    font-size: unit(@Heading-size3/@baseFontSize, em);
}

.Heading--h4, h4 {
    &:extend(.Heading all);

    font-size: unit(@Heading-size4/@baseFontSize, em);
    font-weight:300;
}

.Heading--h5, h5 {
    &:extend(.Heading all);

    font-size: unit(@Heading-size5/@baseFontSize, em);
}

.Heading--h6, h6 {
    &:extend(.Heading all);

    font-size: unit(@Heading-size6/@baseFontSize, em);
}

.normalHeading {
    line-height:1.2em;
    @media @bpSmall {
        line-height:inherit;
    }
}

.sectionHeading {
    line-height:1.2em;
    clear:both;
    text-align:center;
    @media @bpSmall {
        line-height:inherit;
        clear: none;
    }
}

.sectionHeading-alt {
    clear: both;
    font-size: 1em;
    font-weight: normal;
    line-height: 1;
    text-align: center;
    margin-top:-5px;
    margin-bottom:20px;
    @media @bpSmall {
        line-height:inherit;
        clear: none;
    }
}

.sectionHeading-object {
    font-size: 1.1em;
    line-height:1em;
    clear:both;
    text-align:center;
    @media @bpSmall {
        line-height:inherit;
        clear: none;
        font-size: 1.3em;
        line-height:1.3em;
    }

    @media @bpMedium {
        line-height:inherit;
        clear: none;
        font-size: 1.4em;
        line-height:1.4em;
    }
}

.sectionHeading-trading {
    .u-textCenter();

    @media @bpSmall {
        .u-textLeft();
    }

}
