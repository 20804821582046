/*#

Styling for tabs.

Examples:
<div style="width:500px;">
   <div class="tabs" data-module="tabs">
            <ul class="tabs-handles tabs-handles-card ">
                 <li class="tabs-handle" data-name="tab-one"><span class="u-hidden u-sm-inline Icon Icon--tabMosaic"></span><span class="u-inline u-sm-hidden Icon Icon--tab-mob"></span></li>
                 <li class="tabs-handle" data-name="tab-two"><span class="Icon Icon--tabRow"></span></li>
            </ul>

            <div class="tabs-panels">
                <div class="tabs-panel">
                    panel 1
                </div>

                <div class="tabs-panel">
                     panel 2
                </div>
            </div>
        </div>
</div>
*/
@import "../../common/variables.less";

.tabs-panels {
    padding: 0;

    @media @bpSmall {
        padding: 0;
    }
}

.tabs {
    position:relative;

}

.tabs-handles-card-list {
    position:absolute!important;
    right: 0px!important;
    top: -69px!important;
    text-align: left!important;

    @media @bpSmall {
       top: -63px!important;
       right: -33px!important;
       .u-paddingHlg;
    }
}

.tabs-handles-card {
    position: relative;
    text-align:left;
    border-bottom: 1px solid #e0e0e0;
    padding-inline-start: 0px;

    @media @bpSmall {
      
    }

    @media @bpMedium {
      
    }

    li {
        list-style: none;
        display: inline-block;
        color: @colorTextGrey;
        padding-left: 1.5em;
        padding-right: 1.5em;
        margin: 0px;
        cursor: pointer;
        font-size: 14px;
        position: relative;
        top: 1px;


        &:hover, &.is-active {
            color: @colorLink;
            border-bottom: 2px solid @colorLink;
        }
    }
}



.tabs-panel {
    display: none;

    &.is-active {
        display:block;
    }
}

.tabs-row {
    margin-left: -15px;
    margin-right: -15px;

    @media @bpXSmall {
        margin-left: -23px;
        margin-right: -23px;
    }

    @media @bpSmall {
        margin-left: 0;
        margin-right: 0;
    }

}
