@import (reference) "../../common/variables.less";

/*# 

<table class="Table">
    <thead>
        <tr>
            <th>Header 1</th>
            <th>Header 2</th>
            <th><a href="#">Header 3</a></th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Row col 1</td>
            <td>Row col 2</td>
            <td>Row col 3</td>
        </tr>
        <tr>
            <td>Row col 1</td>
            <td>Row col 2</td>
            <td>Row col 3</td>
        </tr>
    </tbody>
</table>

*/

#object-compare{
	
    overflow-x: auto;
	margin-left: 90px;
	white-space: nowrap;
	
	@media @bpLarge{
		margin-left: 104px;
	}
}
.Compare-Table {
	border-collapse:collapse;
	min-width: 615px;
	
	@media @bpLarge{
		min-width: 629px;
	}
	
	td {
		white-space:nowrap;
		background: white;
		border: none;
		border-top: 1px solid #dbdbdb;
		width: 20%;
	}
	
	tr.no-border td{
		border: none !important;
	}
	
	td a{
		white-space: pre-wrap;
	}
	
	td a img{
		height: 6.5em;
		
		@media @bpMedium{
			height: 7.5em;
		}
	}
	
	tr td:first-child{
		position:absolute;
		left: 0;
		width: 90px;
		
		@media @bpLarge{
			width: 104px;
		}
	}
	
	tr td:nth-child(2){
		border-left: 2px solid #dbdbdb;
	}
	
	tr:first-child td a{
		display: inline-block;
		width: 100%;
		line-height: 1.4em;
		
		@media @bpLarge{
			width: 90%;
		}
	}
}

