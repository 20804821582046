/*#

Styling for Trust symbol elements.

Examples:
<div style="width:1024px;">
  <div class="Trust-toggler u-lg-hidden" data-module="toggler" data-toggler="contentElement: '.js-showTrust', cssClass: 'is-active', addClassToSelf: true">
    <span>Fördelar med att köpa bilen av Bilweb’s anslutna bilhandlare</span>
</div>
<div class="Trust-wrapper js-showTrust">
    <div class="Grid Grid--gutterHmd Grid--gutterVsm">
        <div class="Grid-cell u-sm-size1of3 u-lg-size1of5">
            <div class="Trust">
                <div class="Tust-imageWrapper">
                    <img class="Trust-image" src="/assets/images/professionella_icon.svg" width="43">
                </div>
                <h3 class="Trust-heading">Professionella</h3>
                <p class="Trust-text">Begagnade fordon är testade, varudeklara-tion, testprotokoll medföljer</p>
            </div>
        </div>
        <div class="Grid-cell u-sm-size1of3 u-lg-size1of5">
            <div class="Trust">
                <div class="Tust-imageWrapper">
                    <img class="Trust-image" src="/assets/images/testade_icon.svg" width="43">
                </div>
                <h3 class="Trust-heading">Testade</h3>
                <p class="Trust-text">Begagnade fordon är testade, varudeklara-tion, testprotokoll medföljer</p>
            </div>
        </div>
        <div class="Grid-cell u-sm-size1of3 u-lg-size1of5">
            <div class="Trust">
                <div class="Tust-imageWrapper">
                    <img class="Trust-image" src="/assets/images/garanti_icon.svg" width="43">
                </div>
                <h3 class="Trust-heading">Garanti</h3>
                <p class="Trust-text">Begagnade fordon är testade, varudeklara-tion, testprotokoll medföljer</p>
            </div>
        </div>
        <div class="Grid-cell u-sm-size1of3 u-lg-size1of5">
            <div class="Trust">
                <div class="Tust-imageWrapper">
                    <img class="Trust-image" src="/assets/images/inga_dolda_icon.svg" width="43">
                </div>
                <h3 class="Trust-heading">Inga dolda avgifter</h3>
                <p class="Trust-text">Begagnade fordon är testade, varudeklara-tion, testprotokoll medföljer</p>
            </div>
        </div>
        <div class="Grid-cell u-sm-size1of3 u-lg-size1of5">
            <div class="Trust">
                <div class="Tust-imageWrapper">
                    <img class="Trust-image" src="/assets/images/skuldfri_icon.svg" width="43">
                </div>
                <h3 class="Trust-heading">Skuldfri</h3>
                <p class="Trust-text">Begagnade fordon är testade, varudeklara-tion, testprotokoll medföljer</p>
            </div>
        </div>
    </div>
</div>
<div class="Trust-bottomLine u-lg-hidden">
    <div class="Trust-bottomArrow js-showTrust"></div>
</div>

</div>

*/
@import "../../common/variables.less";

.Trust {
    background: @colorGrey1;
    padding: @spaceMedium @spaceXLarge;
    width: 100%;

    @media @bpSmall {
        padding: @spaceSmall @spaceMedium @spaceMedium;
    }
}

.Trust-AdvantageIcons{
	width: auto !important;
}

.Trust--noBg {
    background: transparent;
}

.Trust-heading {
    text-align: center;
    font-size: @textSizeMedium;
}
.Trust-headingDisplay {
    text-align: center;
    font-size: @textSizeXSmall;
    @media @bpMedium {
        font-size: @textSizeXSmall;
    }
    @media @bpLarge {
        font-size: @textSizeXXSmall;
    }
    @media @bpContainer {
        font-size: @textSizeXSmall;
    }

}

.Trust-image {
    margin: 0 auto;
    display: block;
    height:100%;
}

.Trust-text {
    font-size: @textSizeSmall;
    margin: 0;
    text-align: center;

    @media @bpLarge {
        text-align:left;
    }
}

.Trust-Display {
    color:#fff;
    padding:0;
    margin:0;
}

.Tust-imageWrapper {
    height: 65px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.Trust-imageWrapperDisplay {
    height: 48px;
}

/* for ie */
.is-no-flexbox .Trust-wrapper .Trust {
    min-height: 230px;

    .Tust-imageWrapper {
        display: inline;
        margin-top: @spaceMedium;

        .Trust-image {
            margin: @spaceMedium auto @spaceSmall;
        }
    }
}

.is-no-flexbox .objectbanner-content .Trust {
    margin-bottom: @spaceSmall;

    .Trust-image {
        margin-bottom: 0;
    }
}
/* End ie fixes*/

.Trust--inline {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: @spaceMedium;

    .Tust-imageWrapper {
        display: inline-block;
        height: auto;
    }

    .Trust-heading {
        text-align: left;
        display: inline-block;
        margin: 0 0 0 10px;
    }
}

.Trust-toggler {
    text-align: center;
    margin: 0;
    padding: @spaceSmall @spaceLarge 0;
    font-size: @textSizeLarge;
    line-height: 18px;

    &.is-active {
        background: @colorLightGreen;
        color: #fff;
        padding-bottom: @spaceSmall;
    }
}

.Trust-wrapper {
    display: none;
    margin: 0;

    &.is-active {
        display: block;
    }

    @media @bpSmall {
        margin: @spaceMedium 0;
    }

    @media @bpLarge {
        display:block!important; margin: @spaceLarge 0; padding: 0 @spaceMedium;
    }
}

.Trust-bottomLine {
    border-bottom: 1px solid @colorLightGreen;
    margin: 0 -@spaceSmall @spaceMedium;
}

.Trust-bottomArrow {
    background: #fff;
    width: 15px;
    height: 15px;
    border-right: 1px solid @colorLightGreen;
    border-bottom: 1px solid @colorLightGreen;
    transform: rotate(45deg);
    margin: 0 auto;
    top: 8px;
    position: relative;

    &.is-active {
        display:block!important;
        border-right: 1px solid @colorLightGreen;
        border-bottom: 1px solid @colorLightGreen;
        transform: rotate(225deg);
    }
}
