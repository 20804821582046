/*#

This is a "fake" radiobutton build with a span

Examples:

<div>
  
            <span class="radioOption-indicator Icon"></span>
            <span class="is-active radioOption-indicator  Icon"></span>

</div>


*/

@import "../../common/variables.less";

.radioOption-indicator {
    &:after {
        display: inline-block;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        border: 1px solid @colorTextGrey;
        content: "\2611";
        color: #fff;
        padding: 5px 4px;
        font-size: 11px;
        font-weight: 700;
    }

    &.is-active {
        &:after {
            background: @colorLightGreen;
            border: 1px solid @colorLightGreen;
        }
    }
}

