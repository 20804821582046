/*#

Give a short description of the component's use case and its options.
Markdown is allowed for rich editing.

Examples:

<div>
        <header class="Header">
        <div class="Header-fixed u-sizeFull u-colorBgWhite u-zindexMd">
            <div class="Header-primary">
                <div class="Page-container Header-primaryContainer">
                    <div class="Header-logo">
                        <a href="#">
                            <img src="/assets/images/logo/logoUSP.png" class="Header-logoImage u-marginRlg" /><img src="~/assets/images/Logo/logoSmall.png" class="Header-logoImageMob u-marginRxlg" /></a>
                    </div>

                    <nav class="PageNav PageNav--horizontal PageNav--large Header-primaryNav">
                        <ul class="PageNav-list">
                            <li class="PageNav-item">
                                <a href="#" class="PageNav-itemLink is-active u-transitionAsm">Sök eller köp bil</a>
                            </li>
                            <li class="PageNav-item">
                                <a href="#" class="PageNav-itemLink u-transitionAsm">Sälj bil</a>
                            </li>
                            <li class="PageNav-item">
                                <a href="#" class="PageNav-itemLink u-transitionAsm">Mina bilar</a>
                            </li>
                        </ul>
                    </nav>


                    <div class="Header-secondary">
                        <div class="Page-container">
                            <nav class="PageNav PageNav--small Header-secondaryNav">
                                <ul class="PageNav-list">
                                    <li class="PageNav-item">
                                        <a href="#" class="PageNav-itemLink">Annonsera</a>
                                    </li>
                                    <li class="PageNav-item">
                                        <a href="#" class="PageNav-itemLink">Kontakt</a>
                                    </li>
                                </ul>
                                <ul class="PageNav-list">
                                    <li class="PageNav-item">
                                        <a href="#" class="PageNav-itemLink">Om Bilweb</a>
                                    </li>
                                    <li class="PageNav-item">
                                        <a href="#" class="PageNav-itemLink">Logga in</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                     <div class="Header-MobButtons">
                        <button class="Button Header-menuButton"><span class="Icon Icon--search"></span></button>
                        <button class="Button Header-menuButton"  data-module="toggler" data-toggler="contentElement: '.js-showMenu', cssClass: 'is-active', addClassToSelf: true"><i class="Icon Icon--menu"></i></button>
                    </div>
                </div>
            </div>

            <div class="Header-navMobile js-showMenu">
                <nav class="PageNav PageNav--vertical PageNav-mobile u-textWeightBold">
                    <ul class="PageNav-list PageNavList-mobile">
                        <li class="PageNav-item PageNavItem-mobile">
                            <a href="#" class="PageNav-itemLink is-active u-transitionAsm">Sök eller köp bil</a>
                        </li>
                        <li class="PageNav-item PageNavItem-mobile">
                            <a href="#" class="PageNav-itemLink u-transitionAsm">Sälj bil</a>
                        </li>
                        <li class="PageNav-item PageNavItem-mobile">
                            <a href="#" class="PageNav-itemLink u-transitionAsm">Mina bilar</a>
                        </li>
                    </ul>
                </nav>

                <nav class="PageNav PageNav-mobile PageNavMobile-secondaryNav ">
                    <ul class="PageNav-list PageNavList-mobile">
                        <li class="PageNav-item PageNavItem-mobile">
                            <a href="#" class="PageNav-itemLink">Annonsera</a>
                        </li>
                        <li class="PageNav-item  PageNavItem-mobile">
                            <a href="#" class="PageNav-itemLink">Kontakt</a>
                        </li>

                        <li class="PageNav-item  PageNavItem-mobile">
                            <a href="#" class="PageNav-itemLink">Om Bilweb</a>
                        </li>
                        <li class="PageNav-item  PageNavItem-mobile">
                            <a href="#" class="PageNav-itemLink">Logga in</a>
                        </li>
                    </ul>
                </nav>

            </div>
        </div>
    </header>
</div>
*/

@import "../../common/variables.less";

.Header {
    padding: 0;
    background: #fff;
    min-height: 54px;

    @media @bpLarge {
        min-height: 77px;
        padding: 0;
    }
}

.Header-fixed {
    position: fixed;
    z-index: 100 !important;

    @media @bpLarge {
        padding: 3px 0;
    }
}

.Header-secondary {
    display: none;
    padding: 0 @spaceMedium;
    text-align: right;

    li {
        /*line-height: 14px;*/

        a {
            font-size: 11px;
            padding-top: .825em !important;
            padding-bottom: .75em !important;

            &:first-child {
                margin-right: 1px;
            }
        }
    }

    @media @bpMedium {
        display: block;
    }
}

.Header-primary {
    padding: 0;

    @media @bpLarge {
        padding: (@spaceSmall - .5em) @spaceMedium 0 @spaceMedium;
        margin-bottom: 0;
    }
}
.Header-primary i {
    margin-right:2px;
    color: #7C7C7C;
    @media @bpSmall {
        margin-right:4px;
    }
    @media @bpMedium {
        margin-right:6px;
    }
}

.Header-primaryContainer {
    padding: @spaceXXXSmall @spaceSmall;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;

    @media @bpLarge {
        padding: 0;
    }
}

.Header-logo {
}

.Header-logoImage {
    display: none;

    @media @bpLarge {
        display: block;
    }
}

.Header-logoImageMob {
    display: block;
    width: 75px;
    @media @bpLarge {
        display: none;
    }
}

.Header-primaryNav {
    flex: 1 1 auto;
    text-align: left;
    display: none;

    @media @bpMedium {
        display: block;
    }
}


.Header-MobButtons {
    position: absolute;
    right: @spaceXXSmall;
    top:12px;

    @media @bpMedium {
        display: none;
    }

    span {
        color: @colorTextGrey;
        font-weight: 300;
        margin-right: @spaceSmall;
        font-size: 15px;
        vertical-align: top;

        @media @bpSmall {
            font-size: 18px;
        }
    }
}

.Header-navMobile {
    max-height: 0;
    overflow: hidden;
    background: #fff;
    width: 100%;
    z-index: 999;
    /*margin: 0 -@spaceSmall;*/
    overflow: hidden;
    position: absolute;
    /*margin-top: @spaceSmall;*/
    -webkit-box-shadow: 0px 5px 5px 0px rgba(50, 50, 50, 0.5);
    -moz-box-shadow: 0px 5px 5px 0px rgba(50, 50, 50, 0.5);
    box-shadow: 0px 5px 5px 0px rgba(50, 50, 50, 0.5);
    -webkit-transition: 500ms ease-out 0.2s;
    -moz-transition: 500ms ease-out 0.2s;
    -o-transition: 500ms ease-out 0.2s;
    transition: 500ms ease-out 0.2s;

    @media @bpLarge {
        display: none !important;
    }

    &.is-active {
        max-height: 999px;
    }
}

.PageNav-mobile {
    float: none;
}

.PageNavList-mobile {
    float: none;
    background: #fff;
    text-indent: 1em;
}

.PageNavItem-mobile {
    border-bottom: 1px solid #fff;

    a {
        display: block;
    }
}

.PageNavMobile-secondaryNav {
    .PageNavItem-mobile {
        padding: @spaceXSmall 0;
        font-size: @textSizeSmall;
        border-top: 1px solid @colorGrey1;
    }
}


/**/

.c-hamburger {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0 0 0 8px;
    padding: 0;
    width: 30px;
    height: 28px;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
    float: right;
    background: transparent;
}

.c-hamburger:focus {
    outline: none;
}

.c-hamburger span {
    display: block;
    position: absolute;
    top: 13px;
    left: 0;
    right: 0;
    height: 2px;
    background: #676767;
}

.c-hamburger span::before,
.c-hamburger span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #676767;
    content: "";
}

.c-hamburger span::before {
    top: -6px;
}

.c-hamburger span::after {
    bottom: -6px;
}

.c-hamburger--htx span {
    transition: background 0s 0.3s;
}

.c-hamburger--htx span::before,
.c-hamburger--htx span::after {
    transition-duration: 0.3s, 0.3s;
    transition-delay: 0.3s, 0s;
}

.c-hamburger--htx span::before {
    transition-property: top, transform;
}

.c-hamburger--htx span::after {
    transition-property: bottom, transform;
}

/* active state, i.e. menu open */

.c-hamburger--htx.is-active span {
    background: none;
}

.c-hamburger--htx.is-active span::before {
    top: 0;
    transform: rotate(45deg);
}

.c-hamburger--htx.is-active span::after {
    bottom: 0;
    transform: rotate(-45deg);
}

.c-hamburger--htx.is-active span::before,
.c-hamburger--htx.is-active span::after {
    transition-delay: 0s, 0.3s;
}

/* ie fixes  */
.is-no-flexbox .Header-logo,
.is-no-flexbox .Header-primaryNav,
.is-no-flexbox .Header-secondary {
    display: inline-block;
}
/**/

.Header-shadow {
    -webkit-box-shadow: 0 3px 15px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0 3px 15px 0px rgba(0,0,0,0.3);
    box-shadow: 0 3px 15px 0px rgba(0,0,0,0.3);

    /*@media @bpLarge {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }*/
}