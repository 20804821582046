/*#

footer

Examples:

<div>
       <footer class="Footer">
        <div class="Page-container Page-main Pagecontainer-top">
            <div class="Grid Grid--gutterHmd">
                <div class="Grid-cell u-sm-size3of10 Grid--alignMiddle u-sm-flex u-hidden">
                    <div class="Footer-logo">
                        <a href="#">
                            <img src="/assets/images/logo/footerLogo.png" class="Header-logoImage" /> <img src="~/assets/images/Logo/logoSmall.png" class="Header-logoImageMob" />
                        </a>
                    </div>
                </div>
                <div class="Grid-cell u-sm-size4of10 u-size1of1">
                    <h3 class="Footer-header">Kontakt</h3>
                    <ul class="List List--horizontal Footer-list">
                        <li class="List-item u-block">Bilweb Ab</li>
                        <li class="List-item">Hulda lindgrens gata 8</li>
                        <li class="List-item">08-23 30 00</li>
                        <li class="List-item">421 31 Göteborg</li>
                        <li class="List-item"><a href="mailto:info@bilweb.se">info@bilweb.se</a></li>
                    </ul>
                </div>

                <div class="Grid-cell u-sm-size3of10 u-hidden u-sm-block">
                    <h3 class="Footer-header">Information</h3>
                    <ul class="List List--horizontal Footer-list">
                        <li class="List-item"><a href="#">Annonser</a></li>
                        <li class="List-item"><a href="#">Kontakta oss</a></li>
                        <li class="List-item"><a href="#">Om bilwebb</a></li>
                        <li class="List-item"><a href="#">Cookies</a></li>
                        <li class="List-item"><a href="#">Historia</a></li>
                        <li class="List-item"><a href="#">Vilkor</a></li>

                    </ul>
                </div>

            </div>

        </div>


        <div class="Footer-partner">
            <div class="Page-container Footerpartner-items u-hidden u-md-block">
                <div class="Footer-Label"><span>Partners</span></div>
                <div class="Footerpartner-items">
                    <a href="#"><img src="/assets/images/temp/expressen.png" /></a>
                    <a href="#"><img src="/assets/images/temp/tekv.png" /></a>
                    <a href="#"><img src="/assets/images/temp/motor.png" /></a>
                </div>
            </div>
        </div>
    </footer>
</div>
*/

@import "../../common/variables.less";




.Footer {
    background: @colorGrey5;
    padding: @spaceMedium @spaceMedium 0;
    margin-top: @spaceXLarge; 
       @media @bpMedium {
        padding: @spaceMedium 0 0;
    }

    * {
        color: #fff;
    }
}

.Pagecontainer-top {
    padding: 0 0 @spaceLarge;

    @media @bpMedium {
        margin-bottom: @spaceMedium;
    }
}

.Footer-partner {
    margin: 0 -1.25em 0 -1.25em;
    background: #fff;
    border-top: 10px solid @colorLightGreen;
    position:relative;

    @media @bpMedium {
        padding: @spaceLarge 0 @spaceLarge;
        margin:0;
    }
}
.Footer-header {
    font-size: 16/16em;
    border-bottom: 2px solid #fff;
    line-height: 22px;
    margin-bottom: 0;
    @media @bpMedium {
        width:85%;
    }
}

.Footer-list {
    margin-top: @spaceXSmall;
    li {
        font-weight: 300;
        padding:0;

        @media @bpSmall {
            width:100%;
            font-size:@textSizeXSmall;
        }

        @media @bpLarge {
            font-size: @textSizeSmall;
        }

        a {
            font-size: @textSizeSmall;
        }
    }
}

.Footer-Label {
    background: @colorLightGreen;
    padding:2px 8px;
    color: #fff;
    text-align:center;
    display:inline-block;
    position:absolute;
    top:0;
    font-size: @textSizeSmall;
    font-weight:700;
}

.Footerpartner-items {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-around;

}

/* ie fix*/
.is-no-flexbox .Footerpartner-items {
      a {
        margin: 0 100px;
    }
}