@import (reference) "../common/variables.less";

/*
*	Breakpoint based margins & paddings
*
*/

/** 
 * Margins, .u-margin{direction}{size}
 */
.u-spaceMediaQuery(@prefixXXSmall, @bpXXSmall); // XX-Small and wider
.u-spaceMediaQuery(@prefixXSmall, @bpXSmall); // X-Small and wider
.u-spaceMediaQuery(@prefixSmall, @bpSmall); // Small and wider
.u-spaceMediaQuery(@prefixMedium, @bpMedium); // Medium and wider
.u-spaceMediaQuery(@prefixLarge, @bpLarge); // Large and wider
.u-spaceMediaQuery(@prefixXLarge, @bpXLarge); // X-Large and wider

.u-spaceMediaQuery(@prefix: ~'', @breakpoint) {

	// Margins
	.u-bpSpaceZero(margin, @prefix, @breakpoint);
	.u-bpSpace(~"marginA", margin, @prefix, @breakpoint);			// .u-marginA{size}
	.u-bpSpace(~"marginT", margin-top, @prefix, @breakpoint);		// .u-marginT{size}
	.u-bpSpace(~"marginB", margin-bottom, @prefix, @breakpoint);	// .u-marginB{size}
	.u-bpSpace(~"marginR", margin-right, @prefix, @breakpoint);		// .u-marginR{size}
	.u-bpSpace(~"marginL", margin-left, @prefix, @breakpoint);		// .u-marginL{size}
	.u-bpSpaceHorizontal(~"marginH", margin, @prefix, @breakpoint);	// .u-marginH{size}
	.u-bpSpaceVertical(~"marginV", margin, @prefix, @breakpoint);	// .u-marginV{size}

	// Paddigns
	.u-bpSpaceZero(padding, @prefix, @breakpoint);
	.u-bpSpace(~"paddingA", padding, @prefix, @breakpoint);			// .u-paddingA{size}
	.u-bpSpace(~"paddingT", padding-top, @prefix, @breakpoint);		// .u-paddingT{size}
	.u-bpSpace(~"paddingB", padding-bottom, @prefix, @breakpoint);	// .u-paddingB{size}
	.u-bpSpace(~"paddingR", padding-right, @prefix, @breakpoint);	// .u-paddingR{size}
	.u-bpSpace(~"paddingL", padding-left, @prefix, @breakpoint);	// .u-paddingL{size}
	.u-bpSpaceHorizontal(~"paddingH", padding, @prefix, @breakpoint);	// .u-paddingH{size}
	.u-bpSpaceVertical(~"paddingV", padding, @prefix, @breakpoint);	// .u-paddingV{size}
}

.u-bpSpaceZero(@property: margin, @prefix, @breakpoint, @multiplier: 1) {
	@media @breakpoint {
		.u-@{prefix}@{property}Az { @{property}: 0 !important; }
		.u-@{prefix}@{property}Tz { @{property}-top: 0 !important; }
		.u-@{prefix}@{property}Bz { @{property}-bottom: 0 !important; }
		.u-@{prefix}@{property}Rz { @{property}-right: 0 !important; }
		.u-@{prefix}@{property}Lz { @{property}-left: 0 !important; }
		.u-@{prefix}@{property}Hz { @{property}-left: 0 !important; @{property}-right: 0 !important; }
		.u-@{prefix}@{property}Vz { @{property}-top: 0 !important; @{property}-bottom: 0 !important; }
	}
}

/**
 * Mixins used to generate classes.
 */
.u-bpSpace(@className, @property: margin, @prefix, @breakpoint, @multiplier: 1) {
	@media @breakpoint {
		.u-@{prefix}@{className}xxsm {
			@{property}: @spaceXXSmall * @multiplier !important; 
		}
		.u-@{prefix}@{className}xsm {
			@{property}: @spaceXSmall * @multiplier !important; 
		}
		.u-@{prefix}@{className}sm { 
			@{property}: @spaceSmall * @multiplier !important; 
		}
		.u-@{prefix}@{className}md { 
			@{property}: @spaceMedium * @multiplier !important; 
		}
		.u-@{prefix}@{className}lg { 
			@{property}: @spaceLarge * @multiplier !important; 
		}
		.u-@{prefix}@{className}xlg { 
			@{property}: @spaceXLarge * @multiplier !important; 
		}
	}
}

.u-bpSpaceHorizontal(@className, @property: margin, @prefix, @breakpoint, @multiplier: 1) { 
	@media @breakpoint {
		.u-@{prefix}@{className}xxsm {
			@{property}-left: @spaceXXSmall * @multiplier !important;
			@{property}-right: @spaceXXSmall * @multiplier !important; 
		}
		.u-@{prefix}@{className}xsm {
			@{property}-left: @spaceXSmall * @multiplier !important;
			@{property}-right: @spaceXSmall * @multiplier !important; 
		}
		.u-@{prefix}@{className}sm { 
			@{property}-left: @spaceSmall * @multiplier !important;
			@{property}-right: @spaceSmall * @multiplier !important; 
		}
		.u-@{prefix}@{className}md { 
			@{property}-left: @spaceMedium * @multiplier !important;
			@{property}-right: @spaceMedium * @multiplier !important;
		}
		.u-@{prefix}@{className}lg { 
			@{property}-left: @spaceLarge * @multiplier !important;
			@{property}-right: @spaceLarge * @multiplier !important;
		}
		.u-@{prefix}@{className}xlg {
			@{property}-left: @spaceXLarge * @multiplier !important;
			@{property}-right: @spaceXLarge * @multiplier !important;
		}
	}
}

.u-bpSpaceVertical(@className, @property: margin, @prefix, @breakpoint, @multiplier: 1) {
	@media @breakpoint {
		.u-@{prefix}@{className}xxsm {
			@{property}-top: @spaceXXSmall * @multiplier !important;
			@{property}-bottom: @spaceXXSmall * @multiplier !important;  
		}
		.u-@{prefix}@{className}xsm {
			@{property}-top: @spaceXSmall * @multiplier !important;
			@{property}-bottom: @spaceXSmall * @multiplier !important;  
		}
		.u-@{prefix}@{className}sm { 
			@{property}-top: @spaceSmall * @multiplier !important;
			@{property}-bottom: @spaceSmall * @multiplier !important; 
		}
		.u-@{prefix}@{className}md { 
			@{property}-top: @spaceMedium * @multiplier !important;
			@{property}-bottom: @spaceMedium * @multiplier !important; 
		}
		.u-@{prefix}@{className}lg { 
			@{property}-top: @spaceLarge * @multiplier !important;
			@{property}-bottom: @spaceLarge * @multiplier !important; 
		}
		.u-@{prefix}@{className}xlg {
			@{property}-top: @spaceXLarge * @multiplier !important;
			@{property}-bottom: @spaceXLarge * @multiplier !important; 
		}
	}
}