@font-face {
    font-family: 'open_sans_cond';
    src: url('/assets/styles/fonts/opensanscond/OpenSansCondensed-Light.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'open_sans_cond';
    src: url('/assets/styles/fonts/opensanscond/OpenSansCondensed-LightItalic.ttf') format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'open_sans_cond';
    src: url('/assets/styles/fonts/opensanscond/OpenSansCondensed-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
}