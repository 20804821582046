/*#

Component displaying a navigation breadcrumb.

Examples:

<div>
    <div class="Breadcrumb">
        <ol class="Breadcrumb-list">
            <li class="Breadcrumb-item"><a href="#" class="Breadcrumb-itemLink">Hem</a></li>
            <li class="Breadcrumb-item"><a href="#" class="Breadcrumb-itemLink">Köp bil</a></li>
            <li class="Breadcrumb-item"><a href="#" class="Breadcrumb-itemLink">volvo</a></li>
        </ol>
    </div>    
</div>

*/

@import "../../common/variables.less";

@Breadcrumb-textColor: #808080;
@Breadcrumb-linkColor: @colorLink;

.Breadcrumb {
    color: @Breadcrumb-textColor;
    margin: 0;
    font-size: 0.8em;
    padding-left: 0.625em;
    padding-right: 0.625em;
    padding-top: 1.5em;
    padding-bottom: 2em;
    font-weight: 300;

    @media @bpLarge {
        padding-left: 0;
        padding-right: 0;
    }
}

.Breadcrumb span{
    font-size: 0.9em;
}

.Breadcrumb a{
    font-size: 0.9em !important;
}

.Breadcrumb-shadow{
     @media @bpLarge {
        -webkit-box-shadow: 0px 14px 9px -8px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 14px 9px -8px rgba(0,0,0,0.3);
        box-shadow: 0px 14px 9px -8px rgba(0,0,0,0.3);
    }
}

.Breadcrumb-list {
    max-width: @widthContainer;
    list-style: none;
    margin: 0;
    padding: 0;
}

.Breadcrumb-item {
    margin-right: 0.4em;
    display: inline !important;
    font-size: @textSizeSmall !important;
    
    &:after {
        content: '/';
        margin-left: 0.6em;
    }
    
    &:last-child {
        margin-right: 0;
        
        &:after {
            content: none;
        }
    }
}

.Breadcrumb-itemLink {
    font-size: inherit !important;
    display: inline-block;
    padding: 0.2em 0;
    color: @Breadcrumb-linkColor;
}